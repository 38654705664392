/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import {
	Card,
	CardBody,
	Avatar,
	Image,
	Stack,
	Heading,
	CardFooter,
	Button,
	Text,
	useDisclosure,
	AvatarGroup,
	useToast,
} from '@chakra-ui/react';
import { StatusCodes } from 'http-status-codes';
import instance from '../api/api';
import STATUS from '../constants/status';
import StudentRequestDetailsModal from './StudentRequestModal';
import useSWR from 'swr';
import IModule from '../interfaces/IModule';

export interface StudentDetails {
	_id: string;
	firstName: string;
	surname: string;
	emailAddress: string;
	module: string;
	status: string;
}

export interface StudentRequestProps {
	studentRequestDetails: StudentDetails;
	setStudentRequestList: React.Dispatch<any>;
}

export default function StudentRequest({
	studentRequestDetails,
	setStudentRequestList,
}: StudentRequestProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = useState(false);
	const toast = useToast();
	const [modules, setModules] = useState<IModule[]>([]);
	const sendRequest = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setModules(response.data.modules);
		}
		return response.data;
	};

	useSWR('/api/v1/modules', sendRequest);

	const getModuleName = (id: string) => {
		let foundName = '';
		for (const module of modules) {
			if (module._id === id) {
				foundName = module.name;
			}
		}
		return foundName;
	};

	const updateStudentToEnrolled = async (userId: string) => {
		const response = await instance.put(`/api/v1/students/${userId}`, {
			status: STATUS.ENROLLED,
		});

		const enrollmentData = {
			student_id: studentRequestDetails._id,
			module_id: studentRequestDetails.module,
			enrollment_date: new Date(),
		};

		await instance.post('/api/v1/enrollments/enroll', enrollmentData);

		if (response.status === StatusCodes.OK) {
			setStudentRequestList((prevState: any) => {
				return prevState.filter((studentRequest: StudentDetails) => {
					return studentRequest._id !== studentRequestDetails._id;
				});
			});
			setIsLoading(false);
			return toast({
				title: 'Student Enrolled',
				description: 'Student enrolled successfully',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
		}

		setIsLoading(false);
		return toast({
			title: 'Student Not Enrolled',
			description: 'Student enrollment failed',
			status: 'error',
			duration: 9000,
			isClosable: true,
		});
	};

	return (
		<Card
			textAlign="center"
			bg="white"
			border="2px dashed black"
			color="black"
			maxW={['300px', '280px', '300px']}
			mr={['5%', '2%', '5%']}
			mt={['10', '2', '10']}
		>
			<CardBody justifyContent="center">
				<Stack mt="2" spacing="1">
					<Heading
						size={
							(studentRequestDetails.firstName + studentRequestDetails.surname)
								.length > 20
								? 'xs'
								: 'sm'
						}
					>{`${studentRequestDetails.firstName} ${studentRequestDetails.surname}`}</Heading>

					<Text fontWeight={700} fontSize="sm">
						{getModuleName(studentRequestDetails.module)}
					</Text>
				</Stack>
			</CardBody>

			<CardFooter p="0px" pb="15" justifyContent="center">
				<Button
					bg="#00172D"
					border="1px"
					mr="2"
					borderColor="#00172D"
					color="white"
					onClick={() => {
						updateStudentToEnrolled(studentRequestDetails._id);
					}}
					_hover={{
						background: 'white',
						color: 'black',
						border: '1px solid #00172D',
					}}
					isLoading={isLoading}
				>
					Enroll
				</Button>
				<Button
					bg="#00172D"
					border="1px"
					borderColor="#00172D"
					color="white"
					onClick={onOpen}
					_hover={{
						background: 'white',
						color: 'black',
						border: '1px solid #00172D',
					}}
				>
					View
				</Button>

				<StudentRequestDetailsModal
					studentRequestDetails={studentRequestDetails}
					isOpen={isOpen}
					module={getModuleName(studentRequestDetails.module)}
					onClose={onClose}
					isLoading={isLoading}
					updateStudentToEnrolled={updateStudentToEnrolled}
				/>
			</CardFooter>
		</Card>
	);
}
