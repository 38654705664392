/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, ChangeEvent } from 'react';
import {
	Avatar,
	Box,
	Text,
	Button,
	Flex,
	FormControl,
	FormLabel,
	GridItem,
	HStack,
	Heading,
	Input,
	Select,
	SimpleGrid,
	VStack,
	useToast,
} from '@chakra-ui/react';
import { IStudent } from '../interfaces/IStudent';
import validateNames from '../constants/validate';
import validateEmail from '../constants/validateEmail';
import axios from 'axios';
import instance from '../api/api';
import { getUserId } from '../constants/auth';
import { useForm } from 'react-hook-form';
import LoadingSpinner from './LoadingSpinner';
import User from '../images/Vector.svg';

const validate = validateNames();
const validEmail = validateEmail();
export default function StudentProfileForm({
	initialValues,
}: {
	initialValues: IStudent;
}) {
	const modules = [
		{ id: 1, value: 'Clinical Officers', text: 'Clinical Officers' },
		{ id: 2, value: 'Laboratory Technicians', text: 'Laboratory Technicians' },
		{ id: 3, value: 'Nurses/Midwives', text: 'Nurses/Midwives' },
	];
	const [userProfile, setUserProfile] = useState(initialValues);
	const [isLoading, setIsLoading] = useState(false);
	const toast = useToast();
	const userId = getUserId();

	const handleInputChange = (
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value } = e.target;
		setUserProfile({ ...userProfile, [name]: value });
	};

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm<IStudent>();

	const uploadError = {
		firstName: {
			required: 'First name is required',
			pattern: {
				value: validate,
				message: 'Invalid character!!',
			},
		},
		surname: {
			required: 'Last name is required',
			pattern: {
				value: validate,
				message: 'Invalid character!!',
			},
		},
		emailAddress: {
			required: 'Email address is required',
			pattern: {
				value: validEmail,
				message: 'Invalid character!!',
			},
		},
		module: {
			required: 'Module is required',
		},
	};

	const onSubmit = async () => {
		setIsLoading(true);
		const data = {
			firstName: userProfile.firstName,
			surname: userProfile.surname,
			module: userProfile.module,
			emailAddress: userProfile.emailAddress,
		};
		try {
			const response = await instance.put(`/api/v1/students/${userId}`, data);
			toast({
				title: 'Profile Updated',
				description: 'Your profile has been updated successfully',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			setIsLoading(false);
			return response;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response) {
					toast({
						title: 'Profile not updated',
						description: 'Profile could not be updated',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
					setIsLoading(false);
					return error;
				}
			}
			return 'An unexpected error occured';
		}
	};

	const renderUser = (
		<Flex
			w="full"
			h="full"
			p={10}
			alignItems="flex-start"
			direction={{ base: 'column', md: 'row' }}
		>
			<Box w="full">
				<Flex
					direction={{ base: 'column', md: 'row' }}
					align={{ base: 'center' }}
					ml={[0, 0, 50]}
				>
					<Avatar src={User} size={{ base: 'xl', md: 'xl' }} />
					<VStack p={6} align="flex-start">
						<HStack>
							<Text size="sm">
								{userProfile?.firstName} {userProfile?.surname}
							</Text>
						</HStack>
						<Text size="sm" fontWeight={'extrabold'}>
							Course: {userProfile?.module}
						</Text>
					</VStack>
				</Flex>
				<Box w={{ base: 'full', md: '65%' }} ml={[0, 0, 50]}>
					<hr className="line" />
				</Box>
				<Heading
					p={6}
					fontSize={{ base: 'xl' }}
					fontWeight={500}
					ml={[0, 0, 30]}
				>
					Personal Information
				</Heading>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<SimpleGrid
						columns={2}
						columnGap={3}
						rowGap={6}
						w={{ base: 'full', md: '65%' }}
						py={25}
						ml={[0, 0, 50]}
					>
						<GridItem colSpan={{ base: 2, md: 1 }}>
							<FormControl isRequired>
								<FormLabel fontWeight={700} className="label">
									FIRST NAME
								</FormLabel>
								<Input
									placeholder="John"
									{...register('firstName', uploadError.firstName)}
									value={userProfile.firstName}
									onChange={handleInputChange}
								/>
								{errors.firstName && (
									<p className="error">{errors.firstName?.message}</p>
								)}
							</FormControl>
						</GridItem>
						<GridItem colSpan={{ base: 2, md: 1 }}>
							<FormControl isRequired>
								<FormLabel fontWeight={700} className="label">
									LAST NAME
								</FormLabel>
								<Input
									placeholder="Doe"
									{...register('surname', uploadError.surname)}
									value={userProfile.surname}
									onChange={handleInputChange}
								/>
								{errors.surname && (
									<p className="error">{errors.surname?.message}</p>
								)}
							</FormControl>
						</GridItem>
						<GridItem colSpan={{ base: 2, md: 1 }}>
							<FormControl isRequired>
								<FormLabel
									htmlFor="district"
									fontWeight={700}
									className="label"
								>
									COURSE
								</FormLabel>
								<Select
									id="module"
									{...register('module')}
									value={userProfile.module}
									onChange={handleInputChange}
									disabled
								>
									{modules.map((option) => (
										<option key={option.id} value={option.value}>
											{option.text}
										</option>
									))}
								</Select>
								{errors?.module && (
									<p className="error">{errors.module.message}</p>
								)}
							</FormControl>
						</GridItem>
						<GridItem colSpan={{ base: 2, md: 1 }}>
							<FormControl isRequired>
								<FormLabel fontWeight={700} className="label">
									EMAIL ADDRESS
								</FormLabel>
								<Input
									placeholder="farmai@gmail.com"
									data-testid="email"
									{...register('emailAddress', uploadError.emailAddress)}
									value={userProfile.emailAddress}
									onChange={handleInputChange}
								/>
							</FormControl>
						</GridItem>
						<GridItem colSpan={{ base: 2, md: 1 }}>
							<Button
								size="lg"
								w="full"
								data-testid="Loading"
								mt="2rem"
								colorScheme="brand"
								type="submit"
								isLoading={isSubmitting}
							>
								Update
							</Button>
						</GridItem>
					</SimpleGrid>
				</form>
			</Box>
		</Flex>
	);

	return <div>{isLoading ? <LoadingSpinner /> : renderUser}</div>;
}
