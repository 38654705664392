/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useRef, useState } from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerFooter,
	Button,
	Flex,
	Select,
	Box,
	FormLabel,
	Input,
	useDisclosure,
	useToast,
	Stack,
} from '@chakra-ui/react';
import useSWR from 'swr';
import { MdEdit } from 'react-icons/md';
import { ICourse } from '../interfaces/ICourse';
import instance from '../api/api';
import IModule from '../interfaces/IModule';
import { AddIcon } from '@chakra-ui/icons';
import moment from 'moment';
import LoadingSpinner from './LoadingSpinner';
moment().format();
interface EditCourseProps {
	course: ICourse;
}

function EditCourseDrawer({ course }: EditCourseProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [courseUpdate, setCourseUpdate] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const firstField = useRef();
	const courseId = course._id;
	const toast = useToast();
	const btnRef = useRef(null);
	const [objectives, setObjectives] = useState<string[]>(course.objectives);
	const [competencies, setCompetencies] = useState<string[]>(
		course.competencies
	);
	const [presenters, setPresenters] = useState<string[]>(course.presenters);

	const initialObjectives = course.objectives;
	const initialCompetencies = course.competencies;
	const initialPresenters = course.presenters;

	const handleAddObjectives = () => {
		setObjectives([...objectives, '']);
	};

	const handleChangeObjectives = (index: any, event: any) => {
		const updatedObjectives = [...objectives];
		updatedObjectives[index] = event.target.value;
		setObjectives(updatedObjectives);
	};

	const handleAddCompetencies = () => {
		setCompetencies([...competencies, '']);
	};

	const handleChangeCompetencies = (index: any, event: any) => {
		const updatedCompetencies = [...competencies];
		updatedCompetencies[index] = event.target.value;
		setCompetencies(updatedCompetencies);
	};

	const handleAddPresenters = () => {
		setPresenters([...presenters, '']);
	};

	const handleChangePresenters = (index: any, event: any) => {
		const updatedPresenters = [...presenters];
		updatedPresenters[index] = event.target.value;
		setPresenters(updatedPresenters);
	};

	const clearAllArrayValues = () => {
		setObjectives(initialObjectives);
		setCompetencies(initialCompetencies);
		setPresenters(initialPresenters);
	};

	const updateCourse = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			await instance.put(`/api/v1/courses/${courseId}`, courseUpdate);
			setIsLoading(false);
			toast({
				title: 'Course updated',
				description: 'Course updated successfully',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			onClose();
		} catch (error) {
			setIsLoading(false);
			toast({
				title: 'Course update failed',
				description: 'Failed to update course. Please try again',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			onClose();
		}
	};

	return (
		<>
			<Button
				data-testid="editButton"
				name="edit"
				role="button"
				w="auto"
				h={[26, 26, 26]}
				fontSize={14}
				borderRadius={6}
				_hover={{
					bgColor: 'white',
					color: 'black',
					border: '2px solid #BC6C25',
				}}
				bgColor="#BC6C25"
				border="2px solid #BC6C25"
				color="white"
				px={{ base: 4, md: 8 }}
				onClick={() => {
					localStorage.setItem('courseId', course._id);
					onOpen();
				}}
			>
				<span>
					<MdEdit />
				</span>{' '}
				Edit
			</Button>
			<Drawer
				data-testid="editCourseDrawer"
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
				size="sm"
			>
				<DrawerOverlay />
				<DrawerContent bg="#FEFAE0">
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Edit Course</DrawerHeader>

					<DrawerBody>
						<form onSubmit={updateCourse}>
							<Stack spacing="24px">
								<Box>
									<FormLabel fontWeight={700} htmlFor="topic">
										Topic
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												topic: e.target.value,
											}));
										}}
										defaultValue={course.topic}
										id="topic"
										placeholder="Topic"
										required
									/>
								</Box>
								{/* Objectives */}
								<Stack>
									<FormLabel fontWeight={700} htmlFor="objectives">
										Objectives
									</FormLabel>
									{objectives.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangeObjectives(index, e);
												setCourseUpdate((prevState: any) => ({
													...prevState,
													objectives,
												}));
											}}
											id="objective"
											placeholder="Add Objective"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddObjectives}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>
								<Stack>
									<FormLabel fontWeight={700} htmlFor="competencies">
										Competencies
									</FormLabel>
									{competencies.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangeCompetencies(index, e);
												setCourseUpdate((prevState: any) => ({
													...prevState,
													competencies,
												}));
											}}
											id="competency"
											placeholder="Add Competency"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddCompetencies}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>

								<Stack>
									<FormLabel fontWeight={700} htmlFor="presenters">
										Presenters
									</FormLabel>
									{presenters.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangePresenters(index, e);
												setCourseUpdate((prevState: any) => ({
													...prevState,
													presenters,
												}));
											}}
											id="presenter"
											placeholder="Add Presenters"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddPresenters}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>
								<Box>
									<FormLabel fontWeight={700} htmlFor="date">
										Date
									</FormLabel>
									<Input
										type="date"
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												date: e.target.value,
											}));
										}}
										value={course.date}
										// defaultValue={moment(course.date)
										// 	.local()
										// 	.format('dd/mm/yyyy')}
										id="date"
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="time">
										Duration (in minutes)
									</FormLabel>
									<Input
										type="number"
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												time: e.target.value,
											}));
										}}
										defaultValue={course.time}
										id="time"
										placeholder="Duration"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="meetingLink">
										Meeting Link
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												meetingLink: e.target.value,
											}));
										}}
										defaultValue={course.meetingLink}
										id="meetingLink"
										placeholder="Add Meeting Link"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="meetingID">
										Meeting ID
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												meetingID: e.target.value,
											}));
										}}
										defaultValue={course.meetingID}
										id="meetingID"
										placeholder="Add Meeting ID"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="password">
										Meeting Password
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												password: e.target.value,
											}));
										}}
										defaultValue={course.password}
										id="password"
										placeholder="Add Meeting Password"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="sessionEvaluationLink">
										Session Evaluation Link
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												sessionEvaluationLink: e.target.value,
											}));
										}}
										defaultValue={course.sessionEvaluationLink}
										id="sessionEvaluationLink"
										placeholder="Session Evaluation Link"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="formativeAssessmentLink">
										Formative Assessment Link
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setCourseUpdate((prevState: any) => ({
												...prevState,
												formativeAssessmentLink: e.target.value,
											}));
										}}
										defaultValue={course.formativeAssessmentLink}
										id="formativeAssessmentLink"
										placeholder="Formative Assessment Link"
									/>
								</Box>
							</Stack>
							<Flex
								mt="5"
								justifyContent="center"
								flexDir="column"
								as={DrawerFooter}
							>
								<Button
									w="100%"
									mb="2"
									borderColor="#00172D"
									_hover={{
										background: '#00172D',
										color: 'white',
									}}
									variant="outline"
									onClick={() => {
										onClose();
										clearAllArrayValues();
									}}
								>
									Cancel
								</Button>
								<Button
									isLoading={isLoading}
									color="white"
									w="100%"
									_hover={{
										border: '1px solid #00172D',
										bgColor: '#FFF',
										color: 'black',
									}}
									bgColor="#00172D"
									type="submit"
								>
									Update Course
								</Button>
							</Flex>
						</form>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
export default EditCourseDrawer;
