import {
	Box,
	Heading,
	Link,
	ListItem,
	OrderedList,
	Stack,
	Text,
	Flex,
} from '@chakra-ui/react';

export default function About() {
	return (
		<Stack
			p={{ base: 10, lg: 20 }}
			px={{ base: 10, lg: 40 }}
			spacing={10}
			flexDir={{ base: 'column', lg: 'row-reverse' }}
		>
			<Box>
				<Text
					fontWeight={'extrabold'}
					padding={3}
					color={'red'}
					fontStyle={'bold'}
					fontSize={'xl'}
				>
					APPLICATION CLOSED
				</Text>
			</Box>
			<Box>
				<Box>
					<Heading fontSize={'4xl'} mb={6}>
						South Sudan Next Generation short courses
					</Heading>
					<Text fontSize={'xl'} lineHeight="2em">
						It is with great excitement that we announce the South Sudan Next
						Generation short courses for continuous professional development for
						clinical officers, nurses/midwives, and laboratory officers.
						Candidates with an interest in advancing their knowledge and skills
						in their respective fields are encouraged to apply.
					</Text>
				</Box>

				<Box>
					<Heading fontSize={'4xl'} mb={6}>
						Our Course
					</Heading>
					<Text fontSize={'xl'} lineHeight="2em">
						The short courses are designed to provide participants with the
						latest information and skills needed to deliver quality health care.
						We are looking for candidates who are dedicated to improving the
						quality of healthcare services and who are eager to learn and apply
						new skills to their clinical work. The courses will be offered in
						various facilities equipped with ECHO throughout the country, and we
						encourage candidates from all regions to apply.
					</Text>
				</Box>

				<Box>
					<Heading fontSize={'4xl'} mb={6}>
						Requirements
					</Heading>
					<Flex>
						<Text fontSize={'xl'} lineHeight="2em">
							Courses are open to all clinical officers, nurses/midwives, and
							laboratory officers who meet the following requirements:
							<OrderedList px={10}>
								<ListItem>
									Applicants must be practicing in their respective fields at
									the time of application
								</ListItem>
								<ListItem>
									A minimum of one year of experience in their respective fields
									is required
								</ListItem>
								<ListItem>Applicants must be South Sudanese</ListItem>
							</OrderedList>
							The courses will cover a wide range of topics including but not
							limited to:
							<OrderedList px={10}>
								<ListItem>Infection prevention and control</ListItem>
								<ListItem>Quality assurance in laboratory services</ListItem>
								<ListItem>
									The management of communicable and noncommunicable diseases
								</ListItem>
								<ListItem>Surveillance</ListItem>
								<ListItem>Maternal and child health</ListItem>
								<ListItem>Perioperative nursing</ListItem>
								<ListItem>Techniques for staining in the laboratory</ListItem>
							</OrderedList>
							Courses will be taught by experienced and qualified trainers who
							are experts in their fields. The participants will have the
							opportunity to interact with other professionals in their field,
							share experiences, and learn from one another. The courses will be
							offered over a period of four months, and participants are
							expected to attend all sessions, virtually and in select health
							facilities. A certificate of completion will be awarded to
							participants who successfully complete the course. You may apply
							for the course by clicking on the{' '}
							<Link href="/apply" color={'blue'}>
								Apply Now
							</Link>{' '}
							. Interested candidates will be required to fill in the online
							application form and submit the following documents:
						</Text>
					</Flex>
				</Box>
			</Box>
		</Stack>
	);
}
