import {
	isLoggedInAdmin,
	isLoggedInStudent,
	isLoggedInUser,
} from '../constants/auth';

export default function HeaderSelector() {
	if (isLoggedInStudent) {
		return [
			{ label: 'Home', href: '/' },
			{ label: 'Dashboard', href: '/dashboard/student-dashboard' },
			{ label: 'Profile', href: '/dashboard/student-profile' },
		];
	}

	if (isLoggedInAdmin) {
		return [
			{
				label: 'Home',
				href: '/',
			},
			{
				label: 'Dashboard',
				href: '/dashboard/dashboard',
			},
			{
				label: 'Database',
				href: '/dashboard/database',
			},
			{
				label: 'Applications',
				href: '/dashboard/applications',
			},
			{
				label: 'Users',
				href: '/dashboard/all-users',
			},
			{
				label: 'Courses',
				href: '/dashboard/all-courses',
			},
			{
				label: 'Profile',
				href: '/dashboard/admin-profile',
			},
		];
	}

	if (isLoggedInUser) {
		return [
			{
				label: 'Home',
				href: '/',
			},
			{
				label: 'Courses',
				href: '/courses',
			},
			{
				label: 'Apply',
				href: '/apply',
			},
		];
	}

	return [
		{
			label: 'Home',
			href: '/',
		},
		{
			label: 'Courses',
			href: '/courses',
		},
		{
			label: 'Register',
			href: '/register',
		},
		{
			label: 'Login',
			href: '/login',
		},
	];
}
