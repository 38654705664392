/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	useDisclosure,
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	MenuItem,
	Box,
	useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import instance from '../api/api';

interface DeleteButtonDialogProps {
	userId: string;
	userType: string;
	userList: any[];
	setUserList: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function DeleteButtonDialog({
	userId,
	userType,
	userList,
	setUserList,
}: DeleteButtonDialogProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = useState(false);
	const cancelRef = React.useRef();
	const toast = useToast();

	const deleteUser = async () => {
		setIsLoading(true);
		try {
			if (userType === 'Admin') {
				await instance.delete(`/admin/admins/${userId}`);
				setIsLoading(false);
				toast({
					title: 'Admin Deleted',
					description: 'Admin deleted successfully',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
				const newUserList = userList.filter((user) => user._id !== userId);
				setUserList(newUserList);
				onClose();
			}

			if (userType === 'Student') {
				await instance.delete(`/api/v1/students/${userId}`);
				setIsLoading(false);
				toast({
					title: 'Student Deleted',
					description: 'Student deleted successfully',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
				const newUserList = userList.filter((user) => user._id !== userId);
				setUserList(newUserList);
				onClose();
			}

			if (userType === 'Facilitator') {
				await instance.delete(`/api/v1/facilitators/${userId}`);
				setIsLoading(false);
				toast({
					title: 'Facilitator Deleted',
					description: 'Facilitator deleted successfully',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
				const newUserList = userList.filter((user) => user._id !== userId);
				setUserList(newUserList);
				onClose();
			}
		} catch (error) {
			setIsLoading(false);
			toast({
				title: `${userType} Deletion Failed`,
				description: `Deleting ${userType} was unsuccessful. Please try again`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
			onClose();
		}
	};

	return (
		<>
			<Box as={MenuItem} icon={<DeleteIcon />} onClick={onOpen}>
				Delete User
			</Box>

			<AlertDialog
				isOpen={isOpen}
				// @ts-ignore
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete User
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can&apos;t undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								// @ts-ignore
								ref={cancelRef}
								onClick={onClose}
							>
								Cancel
							</Button>
							<Button
								isLoading={isLoading}
								colorScheme="red"
								onClick={() => {
									deleteUser();
								}}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
