function GetMonthName(monthNumber: number) {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	// Get the month name using the month number
	const monthName = monthNames[monthNumber];

	return monthName;
}

export default GetMonthName;
