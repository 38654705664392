import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
	// colors: {
	// 	brand: {
	// 		500: '#1C6B28',
	// 	},
	// 	tigersEye: {
	// 		500: '#BC6C25',
	// 	},
	// 	barnOrange: {
	// 		500: '#BC6C25',
	// 	},
	// 	darkMossGreen: {
	// 		500: '#606C38',
	// 	},
	// },
	fonts: {
		body: 'Source Sans Pro',
	},
});

export default theme;
