import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

export default function Ineligible() {
	const navigate = useNavigate();

	const error1 = localStorage.getItem('error1');
	const error2 = localStorage.getItem('error2');
	const error3 = localStorage.getItem('error3');
	const error4 = localStorage.getItem('error4');

	const handleSubmit = () => {
		navigate('/');
	};
	return (
		<div>
			<Navbar />
			<Flex justifyContent="center">
				<Flex
					align="center"
					direction="column"
					mt="6em"
					border="1px solid #DADADA"
					alignItems="center"
				>
					<Box
						border="2px solid green"
						w={[300, 400, 500]}
						textAlign="center"
						height="100px"
						backgroundColor="#00172D"
					>
						<Text mt="5%" color="white" fontSize="3xl" fontWeight={400}>
							Crieria Not Met
						</Text>
					</Box>
					<Flex
						flexDirection={'column'}
						marginTop="22px"
						mb="36px"
						w={[250, 350, 450]}
					>
						{error1 && <Text>{error1}</Text>}
						{error2 && <Text>{error2}</Text>}
						{error3 && <Text>{error3}</Text>}
						{error4 && <Text>{error4}</Text>}
					</Flex>
					<Button
						w={[250, 350, 450]}
						mb="20px"
						backgroundColor="#00172D"
						color="white"
						p="7"
						fontSize="xl"
						onClick={handleSubmit}
					>
						Continue
					</Button>
				</Flex>
			</Flex>
		</div>
	);
}
