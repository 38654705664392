import {
	useDisclosure,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	UnorderedList,
	ListItem,
	Box,
	Text,
	CloseButton,
	Button,
} from '@chakra-ui/react';

function WelcomeAlert() {
	const firstName = localStorage.getItem('firstName');
	const {
		isOpen: isVisible,
		onClose,
		onOpen,
	} = useDisclosure({ defaultIsOpen: true });

	return isVisible ? (
		<Alert status="success" borderRadius={15} w={'full'}>
			<AlertIcon />
			<Box>
				<AlertTitle>Dear {firstName}!</AlertTitle>
				<AlertDescription>
					<Text fontWeight={700}>Welcome note</Text>
					Welcome to the student portal for our short course program for
					continuous professional development. We are thrilled that you have
					been successfully selected for this program and look forward to
					supporting your learning journey. This student portal is your
					one-stop-shop for:
					<UnorderedList>
						<ListItem>tracking your progess</ListItem>
						<ListItem>viewing session details</ListItem>
						<ListItem>
							accessing links, dates, & times for upcoming sessions.
						</ListItem>
					</UnorderedList>
					<Text fontWeight={700}>Program Expectations</Text>
					Please ensure that you attend all sessions and achieve a total
					assessment score of over 95% to successfully complete the program.
					<Text></Text>
					<Text fontWeight={700}>Academic Integrity</Text>
					We would like to remind you of the academic integrity expected of you
					throughout the program. Plagiarism and any other forms of academic
					dishonesty will not be tolerated, and appropriate action will be taken
					should such occur.
					<Text></Text>
					<Text fontWeight={700}>Student Assistance</Text>
					Should you require further assistance, please do not hesitate to reach
					out to our course coordinator on +211XXX XXX XXX. We are committed to
					ensuring that you have a smooth and enriching learning experience. We
					wish you all the best as you embark on this journey of continuous
					professional development.
					<Text></Text>
					<Text fontWeight={700}>South Sudan Project ECHO</Text>
				</AlertDescription>
			</Box>
			<CloseButton
				alignSelf="flex-start"
				position="relative"
				right={-1}
				top={-1}
				onClick={onClose}
			/>
		</Alert>
	) : (
		<Button onClick={onOpen}>Show Welcome Message</Button>
	);
}

export default WelcomeAlert;
