/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
	Text,
	Box,
	Heading,
	Stack,
	Flex,
	Grid,
	GridItem,
} from '@chakra-ui/react';
import { useState } from 'react';
import Header from '../components/AdminHeader';
import useSWR from 'swr';
import { BiBody } from 'react-icons/bi';
import instance from '../api/api';
import {
	BarChart,
	Bar,
	CartesianGrid,
	YAxis,
	XAxis,
	PieChart,
	Pie,
	Cell,
	Legend,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import LoadingSpinner from '../components/LoadingSpinner';
import UserSummaryCard from '../components/UserSummaryCard';
import '../styles/adminDashboard.css';
import IModule from '../interfaces/IModule';
import { RiFolderKeyholeFill } from 'react-icons/ri';

export default function AdminDashboard() {
	const [dashboardStats, setDashboardStats] = useState({});

	const [numberOfApplicantsByGender, setNumberOfApplicantsByGender] = useState([
		{
			_id: '',
			count: 0,
		},
	]);
	const [numberOfApplicantsByCadre, setNumberOfApplicantsByCadre] = useState([
		{
			_id: '',
			name: '',
			count: 0,
		},
	]);
	const [numberOfApplicantsByProfession, setNumberOfApplicantsByProfession] =
		useState([
			{
				_id: '',
				count: 0,
			},
		]);
	const [
		numberOfEnrolledApplicantsByGender,
		setNumberOfEnrolledApplicantsByGender,
	] = useState([
		{
			_id: '',
			count: 0,
		},
	]);
	const [
		numberOfEnrolledApplicantsByCadre,
		setNumberOfEnrolledApplicantsByCadre,
	] = useState([
		{
			_id: '',
			count: 0,
		},
	]);
	const [
		numberOfEnrolledApplicantsByProfession,
		setNumberOfEnrolledApplicantsByProfession,
	] = useState([
		{
			_id: '',
			count: 0,
		},
	]);
	const [totalApplicants, setTotalApplicants] = useState(0);
	const [totalEnrolled, setTotalEnrolled] = useState(0);

	const COLORS = ['#606C38', '#BC6C25', '#FFBB28'];

	const getDashboardStats = async (url: string) => {
		const response = await instance.get(url);
		console.log(response.data);
		if (response.data) {
			setDashboardStats(response.data);
			setNumberOfApplicantsByGender(response.data.numberOfApplicants.gender);
			setNumberOfApplicantsByCadre(response.data.numberOfApplicants.cadre);
			setNumberOfApplicantsByProfession(
				response.data.numberOfApplicants.profession
			);

			setNumberOfEnrolledApplicantsByGender(
				response.data.numberOfEnrolledApplicants.gender
			);
			setNumberOfEnrolledApplicantsByCadre(
				response.data.numberOfEnrolledApplicants.cadre
			);
			setNumberOfEnrolledApplicantsByProfession(
				response.data.numberOfEnrolledApplicants.profession
			);
			setTotalApplicants(response.data.totalNumberOfApplicants);
			setTotalEnrolled(response.data.totalEnrolledApplicants);
		}
		return response.data;
	};

	const { data: stats, error: statsError } = useSWR(
		'/admin/dashboardStats',
		getDashboardStats
	);

	const legendTextColor = (legendText: string) => {
		return (
			<Text color="black" display="inline">
				{legendText}
			</Text>
		);
	};

	if (statsError || !stats) {
		return (
			<div>
				<Header />
				<LoadingSpinner />
			</div>
		);
	}
	return (
		<Box>
			<Header />
			<Box mt={'1em'} padding={8}>
				<Heading>Dashboard</Heading>
				<hr className="line" />
			</Box>
			{/* Number of hcws applied for the program stats */}
			<Flex p="2" flexDir={['column', 'row']} mb={['2', '2', '3']} gap="10px">
				<UserSummaryCard
					title="Total Applicants"
					icon={BiBody}
					activeUsersCount={totalApplicants}
				/>
				<UserSummaryCard
					title="Total Enrolled Students"
					icon={BiBody}
					activeUsersCount={totalEnrolled}
				/>
			</Flex>
			<Box border="1px" borderRadius="md" p={4}>
				<Text fontWeight={700} fontSize="1.3em" textAlign="center">
					Number of HCWS Applied For The Short Course
				</Text>
				<Flex
					flexGrow="1"
					textAlign="center"
					flexDirection={{ base: 'column', lg: 'row' }}
				>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Pie
								data={numberOfApplicantsByGender}
								dataKey="count"
								nameKey="_id"
								cx="50%"
								cy="50%"
								innerRadius={40}
								outerRadius={80}
								fill="#82ca9d"
								label
							>
								{numberOfApplicantsByGender.map((entry, index) => (
									<Cell
										key={`cell-${entry._id}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>
							<Tooltip />
							<Legend
								formatter={legendTextColor}
								verticalAlign="bottom"
								height={36}
							/>
						</PieChart>
					</ResponsiveContainer>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Pie
								data={numberOfApplicantsByCadre}
								dataKey="count"
								nameKey="_id"
								cx="50%"
								cy="50%"
								innerRadius={40}
								outerRadius={80}
								fill="#82ca9d"
								label
							>
								{numberOfApplicantsByCadre.map((entry, index) => (
									<Cell
										key={`cell-${entry._id}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>
							<Tooltip />
							<Legend
								formatter={legendTextColor}
								verticalAlign="bottom"
								height={36}
							/>
						</PieChart>
					</ResponsiveContainer>
					<ResponsiveContainer width="100%" height={300}>
						<BarChart
							width={730}
							height={250}
							data={numberOfApplicantsByProfession}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="_id" />
							<YAxis />
							<Tooltip />
							<Legend />
							<Bar dataKey="count" fill="#8884d8" />
						</BarChart>
					</ResponsiveContainer>
				</Flex>
			</Box>
			{/* Number of enrolled hcws */}
			<Box border="1px" borderRadius="md" mt={4} p={4}>
				<Text fontWeight={700} fontSize="1.3em" textAlign="center">
					Number of HCWS Enrolled For The Short Course
				</Text>
				<Flex
					flexGrow="1"
					textAlign="center"
					flexDirection={{ base: 'column', lg: 'row' }}
				>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Pie
								data={numberOfEnrolledApplicantsByGender}
								dataKey="count"
								nameKey="_id"
								cx="50%"
								cy="50%"
								innerRadius={40}
								outerRadius={80}
								fill="#82ca9d"
								label
							>
								{numberOfEnrolledApplicantsByGender.map((entry, index) => (
									<Cell
										key={`cell-${entry._id}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>
							<Tooltip />
							<Legend
								formatter={legendTextColor}
								verticalAlign="bottom"
								height={36}
							/>
						</PieChart>
					</ResponsiveContainer>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Pie
								data={numberOfEnrolledApplicantsByCadre}
								dataKey="count"
								nameKey="_id"
								cx="50%"
								cy="50%"
								innerRadius={40}
								outerRadius={80}
								fill="#82ca9d"
								label
							>
								{numberOfEnrolledApplicantsByCadre.map((entry, index) => (
									<Cell
										key={`cell-${entry._id}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>
							<Tooltip />
							<Legend
								formatter={legendTextColor}
								verticalAlign="bottom"
								height={36}
							/>
						</PieChart>
					</ResponsiveContainer>
					<ResponsiveContainer width="100%" height={300}>
						<BarChart
							width={730}
							height={250}
							data={numberOfEnrolledApplicantsByProfession}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="_id" />
							<YAxis />
							<Tooltip />
							<Legend />
							<Bar dataKey="count" fill="#8884d8" />
						</BarChart>
					</ResponsiveContainer>
				</Flex>
			</Box>
		</Box>
	);
}
