/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	SyntheticEvent,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Link,
	Image,
	Stack,
	FormControl,
	FormLabel,
	InputGroup,
	InputLeftElement,
} from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import validateEmail from '../constants/validateEmail';
import validatePhoneNumber from '../constants/validatePhoneNumber';
import { AiOutlineMail } from 'react-icons/ai';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import AuthContext from '../context/Auth/AuthContext';
import instance from '../api/api';

interface DecodedTokenProps {
	role: string;
	firstName: string;
	module: string;
}

type ILogin = {
	username: string;
	password: string;
};

export default function Login() {
	const [passwordShown, setPasswordShown] = useState(false);
	const { setUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const isValid = (errors: ILogin) => {
		if (errors.username === '') {
			return true;
		}
		return false;
	};

	const initialValue = {
		username: '',
		password: '',
	};

	const [formValue, setFormValue] = useState(initialValue);
	const [formErrors, setFormErrors] = useState({
		username: '',
		password: '',
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [apiResponse, setApiResponse] = useState('');

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormValue({ ...formValue, [name]: value });
	};

	const validate = (value: ILogin) => {
		let isEmail = false;
		let isPhoneNumber = false;

		const errors = {
			username: '',
			password: '',
		};

		const textRegex = /^[a-z]/i;
		if (!value.username) {
			errors.username = 'Phone Number or Email Required';
		}
		if (!value.password) {
			errors.password = 'Password is required';
		}

		if (value.username.includes('@') || textRegex.test(value.username))
			isEmail = true;
		if (isEmail) {
			if (!validateEmail().test(value.username)) {
				errors.username = 'Invalid email format';
			}
		}

		if (validatePhoneNumber().test(value.username)) {
			isPhoneNumber = true;
		}

		if (isPhoneNumber) {
			if (value.username.length !== 8) {
				errors.username = 'Invalid phone number';
			}
		}

		return errors;
	};

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		setFormErrors(validate(formValue));
		setIsSubmitting(true);
	};

	const login = useCallback(
		(response: AxiosResponse) => {
			setApiResponse(response.data.message);
			const { token } = response.data;
			const { userId } = response.data;

			if (token) {
				const decoded = jwtDecode<DecodedTokenProps>(token);
				const decodedRole = decoded?.role;
				const role = decodedRole;
				const firstName = decoded?.firstName;
				const moduleId = decoded?.module;

				localStorage.setItem('userId', userId);
				localStorage.setItem('token', token);
				localStorage.setItem('role', role);
				localStorage.setItem('firstName', firstName);
				localStorage.setItem('module', moduleId);
				setUser({ token, userId, role });
				switch (role) {
					case 'Student': {
						navigate('/');
						window.location.reload();
						break;
					}
					case 'Admin': {
						navigate('/');
						window.location.reload();
						break;
					}
					default:
						navigate('/');
						window.location.reload();
				}
			}
			formValue.username = '';
		},
		[formValue, navigate, setUser]
	);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	useEffect(() => {
		const handleLogin = async (data: ILogin) => {
			try {
				setIsLoggingIn(true);
				setApiResponse('');
				const res = await instance
					.post('/login', {
						username: data.username,
						password: data.password,
					})
					.then((response) => {
						login(response);
						return response;
					})
					.catch((error) => {
						setIsLoggingIn(error.response.data.success);
						setApiResponse(error.response.data.message);
						return error;
					});
				return res;
			} catch (error) {
				setIsLoggingIn(false);
				return error;
			}
		};
		if (isValid(formErrors) && isSubmitting) {
			handleLogin(formValue);
		}
	}, [formErrors, formValue, isSubmitting, login, navigate, setUser]);

	return (
		<div>
			<Navbar />
			<Stack h="100vh" align={'center'}>
				<form onSubmit={handleSubmit} noValidate>
					<Stack>
						<Flex
							direction="column"
							justifyContent="center"
							alignSelf="center"
							mt="1em"
							w={[300, 400, 500]}
						>
							<Box
								w={[300, 400, 500]}
								textAlign="center"
								height="100px"
								mb="5%"
							>
								<Text
									color="black"
									fontSize={{ base: '3xl', md: '5xl' }}
									fontWeight={400}
								>
									Welcome back
								</Text>
								<Text>Let&apos;s log you into your account</Text>
							</Box>
							<FormControl isRequired>
								<FormLabel fontWeight={600} className="label">
									Email address
								</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents="none">
										<AiOutlineMail />
									</InputLeftElement>
									<Input
										borderColor="#D9D9D9"
										placeholder="Email Address"
										name="username"
										required
										value={formValue.username}
										onChange={handleChange}
										mb="10px"
										fontWeight={200}
									/>
								</InputGroup>
								{formErrors.username && (
									<span className="error">{formErrors.username}</span>
								)}
							</FormControl>
							<FormControl isRequired>
								<FormLabel fontWeight={600} className="label">
									Password
								</FormLabel>
								<InputGroup>
									<InputLeftElement>
										{!passwordShown ? (
											<BsFillEyeFill onClick={togglePassword} />
										) : (
											<BsFillEyeSlashFill onClick={togglePassword} />
										)}
									</InputLeftElement>
									<Input
										type={passwordShown ? 'text' : 'password'}
										borderColor="#D9D9D9"
										role="textbox"
										placeholder="Password"
										name="password"
										required
										value={formValue.password}
										onChange={handleChange}
										mb="10px"
										fontWeight={200}
									/>
								</InputGroup>
								{formErrors.password && (
									<span className="error">{formErrors.password}</span>
								)}
								{apiResponse !== null && (
									<span className="response">{apiResponse}</span>
								)}
							</FormControl>
							<Button
								type="submit"
								alignContent="left"
								fontSize="xl"
								color="white"
								bgColor={'#00172D'}
								isLoading={isLoggingIn}
								_hover={{
									border: '2px solid #00172D',
									color: 'black',
									bgColor: 'white',
								}}
							>
								Submit
							</Button>
							<Flex
								direction="row"
								justifyContent={'space-around'}
								alignItems="center"
								mt={5}
								mb={7}
							>
								<Text p={1}>
									<Link
										aria-label="sign up"
										color="#00172D"
										fontWeight={500}
										textDecoration="underline"
										href="/register"
									>
										Not Registered?{' '}
									</Link>
								</Text>
								<Text p={1}>
									<Link
										aria-label="sign up"
										color="#00172D"
										fontWeight={500}
										textDecoration="underline"
										href="/register"
									>
										Forgot Password?{' '}
									</Link>
								</Text>
							</Flex>
						</Flex>
					</Stack>
				</form>
			</Stack>
		</div>
	);
}
