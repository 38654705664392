import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

export default function Success() {
	const navigate = useNavigate();

	const handleSubmit = () => {
		navigate('/');
	};
	return (
		<div>
			<Navbar />
			<Flex justifyContent="center">
				<Flex
					align="center"
					direction="column"
					mt="6em"
					border="1px solid #DADADA"
					alignItems="center"
				>
					<Box
						border="2px solid green"
						w={[300, 400, 500]}
						textAlign="center"
						height="100px"
						backgroundColor="#00172D"
					>
						<Text mt="5%" color="white" fontSize="3xl" fontWeight={400}>
							Application Successful!
						</Text>
					</Box>
					<Flex marginTop="22px" mb="36px" w={[250, 350, 450]}>
						<Text p={2}>
							Congratulations your application was successful! You will receive
							an email indicating whether your application was successful or
							not.
						</Text>
					</Flex>
					<Button
						w={[250, 350, 450]}
						mb="20px"
						backgroundColor="#00172D"
						color="white"
						p="7"
						fontSize="xl"
						onClick={handleSubmit}
					>
						Continue
					</Button>
				</Flex>
			</Flex>
		</div>
	);
}
