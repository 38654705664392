/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Header from '../components/AdminHeader';
import AddNewTrainingDrawer from '../components/AddNewTrainingDrawer';
import { ITraining } from '../interfaces/ITraining';
import instance from '../api/api';
import useSWR from 'swr';
import AllTrainingsTable from '../components/AllTrainingsTable';

export default function AdminDatabase() {
	const [trainings, setTrainings] = useState<ITraining[]>([]);
	const toast = useToast();

	const getTrainings = async (url: string) => {
		try {
			const response = await instance.get(url);
			setTrainings(response.data.trainings);
		} catch (error) {
			toast({
				title: 'Failed to load trainings',
				description: 'Fetching trainings failed. Please reload the page',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	};

	useSWR('/api/v1/trainings', getTrainings);

	return (
		<Box>
			<Header />
			<Box className="title" pt="34">
				<Flex
					pt="20px"
					pl={[5, 10, 59.9]}
					direction={{ base: 'column', md: 'row' }}
					mb="5"
				>
					<Text
						color="#303133"
						fontWeight="bold"
						fontSize="3xl"
						pb="15px"
						pl={[0, 0, 5]}
						w={['100%', '100%', '30%']}
					>
						Training Database
					</Text>

					<Flex
						w="100%"
						justifyContent={['flex-start', 'flex-start', 'flex-end']}
						alignItems={['flex-start', 'center']}
						flexDir={['column', 'row']}
						pr="55"
						gap={10}
					>
						<AddNewTrainingDrawer />
					</Flex>
				</Flex>
				<AllTrainingsTable trainings={trainings} setTrainings={setTrainings} />
			</Box>
		</Box>
	);
}
