/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Card,
	CardBody,
	Heading,
	Divider,
	CardFooter,
	ButtonGroup,
	Button,
	Image,
	Link,
} from '@chakra-ui/react';

interface CourseCardProps {
	image: any;
	title: string;
	brochureLink: string;
}
export default function CourseCard({
	image,
	title,
	brochureLink,
}: CourseCardProps) {
	return (
		<Card
			maxW="sm"
			borderRadius="15px"
			boxShadow="5px 5px 5px rgba(0, 0, 0, 0.25)"
		>
			<CardBody>
				<Heading size="md">{title}</Heading>
				<Image
					src={image}
					w={314}
					h={292}
					alt="Course image"
					borderRadius="lg"
				/>
				<Link href={`${brochureLink}`} color={'blue'}>
					DOWNLOAD BROCHURE
				</Link>
			</CardBody>
			<Divider />
			<CardFooter justifyContent="center">
				<ButtonGroup spacing="2">
					<Link href="/apply">
						<Button
							variant="solid"
							bgColor="#00172D"
							color="white"
							borderRadius="15px"
							boxShadow="15px 15px 15px rgba(0, 0, 0, 0.25)"
							_hover={{
								border: '2px solid black',
								bgColor: 'white',
								color: 'black',
							}}
						>
							Apply Now
						</Button>
					</Link>
				</ButtonGroup>
			</CardFooter>
		</Card>
	);
}
