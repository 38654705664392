import React, { ReactNode, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getRole, getToken } from '../constants/auth';
import ROLES from '../constants/roles';
import AuthContext from '../context/Auth/AuthContext';

interface ProtectedRouteProps {
	route: ReactNode;
	path: string;
}
function ProtectedRoute(component: ProtectedRouteProps) {
	const { route, path } = component;
	const token = getToken();
	return (
		<Routes>
			<Route
				path={`/${path}`}
				element={token ? route : <Navigate replace to="/login" />}
			/>
		</Routes>
	);
}

function StudentRoutes(component: ProtectedRouteProps) {
	const { user } = useContext(AuthContext);
	const token = getToken() || user?.token;
	const role = getRole() || user?.role;
	const isStudent = role === ROLES.STUDENT;
	const { route, path } = component;
	return (
		<Routes>
			<Route
				path={`/dashboard/${path}`}
				element={token && isStudent ? route : <Navigate replace to="/login" />}
			/>
		</Routes>
	);
}

function AdminRoutes(component: ProtectedRouteProps) {
	const token = getToken();
	const role = getRole();
	const isAdmin = role === ROLES.ADMIN;
	const { route, path } = component;
	return (
		<Routes>
			<Route
				path={`/dashboard/${path}`}
				element={token && isAdmin ? route : <Navigate replace to="/login" />}
			/>
		</Routes>
	);
}

function UserRoutes(component: ProtectedRouteProps) {
	const { user } = useContext(AuthContext);
	const token = getToken() || user?.token;
	const role = getRole() || user?.role;
	const isUser = role === ROLES.USER;
	const { route, path } = component;
	return (
		<Routes>
			<Route
				path={`/${path}`}
				element={token && isUser ? route : <Navigate replace to="/login" />}
			/>
		</Routes>
	);
}

function OtherRoutes(component: ProtectedRouteProps) {
	const token = getToken();
	const { route, path } = component;
	return (
		<Routes>
			<Route
				path={`/${path}`}
				element={!token ? route : <Navigate replace to="/" />}
			/>
		</Routes>
	);
}

export default {
	ProtectedRoute,
	StudentRoutes,
	AdminRoutes,
	OtherRoutes,
	UserRoutes,
};
