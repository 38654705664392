/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import { getUserId } from '../constants/auth';
import Header from '../components/StudentHeader';
import LoadingSpinner from '../components/LoadingSpinner';
import useSWR from 'swr';
import instance from '../api/api';
import StudentProfileForm from '../components/StudentProfileForm';

export default function StudentProfile() {
	const userId = getUserId();
	const [initialValues, setInitialValues] = useState({
		_id: '',
		firstName: '',
		surname: '',
		emailAddress: '',
		module: '',
		password: '',
		phoneNumber: '',
		gender: '',
		dateOfBirth: '',
		nationalIdentificationNumber: '',
		address: '',
		country: '',
		// Academic qualifications
		graduationYear: 2023,
		schoolAttended: '',
		countryOfSchoolAttended: '',
		qualification: '',
		// Experience
		profession: '',
		facility: '',
	});

	const sendRequest = async (url: string) => {
		const response = await instance.get(url);
		if (response.data) {
			setInitialValues(response.data.student);
		}
		return response.data;
	};

	const { data, error } = useSWR(`/api/v1/students/${userId}`, sendRequest);
	if (!data || error) {
		return (
			<div>
				<Header />
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div>
			<Header />
			{initialValues.firstName !== '' ? (
				<StudentProfileForm initialValues={initialValues} />
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
}
