/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	Flex,
	FormLabel,
	Input,
	Select,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { read, utils } from 'xlsx';
import React, { useState } from 'react';
import { ITraining } from '../interfaces/ITraining';
import instance from '../api/api';
import { AddIcon } from '@chakra-ui/icons';
import { IFacilitator } from '../interfaces/IFacilitator';

export default function AddNewTrainingDrawer() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newTraining, setNewTraining] = useState<ITraining>();
	const [objectives, setObjectives] = useState<string[]>([]);
	const toast = useToast();

	const [facilitatorsData, setFacilitatorsData] = useState<any>(null);
	const [participantsData, setParticipantsData] = useState<any>(null);

	const convertFacilitatorData = (imported_data: any[] | null) => {
		const convertedData: any[] = [];
		imported_data?.forEach((entry) => {
			const convertedEntry = {
				firstName: entry['First Name'],
				surname: entry['Last Name'],
				sex: entry.Sex,
				cadre: entry.Cadre,
				position: entry.Position,
				facility: entry['Facility/Organization'],
				phoneNumber: entry['Phone Number'],
				emailAddress: entry['Email Address'],
			};
			convertedData.push(convertedEntry);
		});
		return convertedData;
	};

	const convertParticipantData = (imported_data: any[] | null) => {
		const convertedData: any[] = [];
		imported_data?.forEach((entry) => {
			const convertedEntry = {
				firstName: entry['First Name'],
				surname: entry['Last Name'],
				gender: entry.Sex,
				cadre: entry.Cadre,
				position: entry.Position,
				facility: entry['Facility/Organization'],
				phoneNumber: entry['Phone Number'],
				emailAddress: entry['Email Address'],
			};
			convertedData.push(convertedEntry);
		});
		return convertedData;
	};

	const handleFacilitatorFileChange = (e: any) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			const binaryData = e.target?.result;
			const workbook = read(binaryData, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData: any = utils.sheet_to_json(sheet);

			setFacilitatorsData(jsonData);
		};
		if (file) reader.readAsBinaryString(file);
	};
	convertFacilitatorData(facilitatorsData);
	const handleParticipantFileChange = (e: any) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			const binaryData = e.target?.result;
			const workbook = read(binaryData, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData: any = utils.sheet_to_json(sheet);

			setParticipantsData(jsonData);
		};
		if (file) reader.readAsBinaryString(file);
	};
	convertParticipantData(participantsData);

	const handleAddObjectives = () => {
		setObjectives([...objectives, '']);
	};

	const handleChangeObjectives = (index: any, event: any) => {
		const updatedObjectives = [...objectives];
		updatedObjectives[index] = event.target.value;
		setObjectives(updatedObjectives);
	};

	const clearArrayValues = () => {
		setObjectives([]);
	};

	const addNewTraining = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			const response = await instance.post(
				'/api/v1/trainings/add-training',
				newTraining
			);
			toast({
				title: 'Training Added',
				description: 'New training successfully added',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			console.log(response.data);
			console.log(facilitatorsData);
			for (const d of convertFacilitatorData(facilitatorsData)) {
				await instance.post('/api/v1/facilitators/add-facilitator', {
					...d,
					trainingId: response.data.trainingId,
				});
			}
			toast({
				title: 'Facilitators uploaded successfully',
				description: 'All facilitators uploaded',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			for (const d of convertParticipantData(participantsData)) {
				await instance.post('/api/v1/participants/add-participant', {
					...d,
					trainingId: response.data.trainingId,
				});
			}
			toast({
				title: 'Students uploaded successfully',
				description: 'All students uploaded',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});

			setIsSubmitting(false);
			clearArrayValues();
			onClose();
			return;
		} catch (error) {
			toast({
				title: 'Training addition failed',
				description: 'Failed to add new training. Please try again...',
				status: 'error',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
		}
		setFacilitatorsData([]);
	};
	return (
		<>
			<Button
				bgColor="#00172D"
				color="white"
				border="1px solid #00172D"
				borderRadius="6px"
				textColor="white"
				_hover={{
					textColor: 'black',
					bgColor: 'white',
					border: '1px solid #00172D',
				}}
				leftIcon={<AddIcon color={'white'} />}
				onClick={onOpen}
			>
				Add Training
			</Button>
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					onClose();
					clearArrayValues();
				}}
			>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBlockStartWidth="1px">
						Add New Training
					</DrawerHeader>

					<DrawerBody>
						<form onSubmit={addNewTraining}>
							<Stack spacing="24px">
								<Box>
									<FormLabel fontWeight={700} htmlFor="organization">
										Organization
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewTraining((prevState: any) => ({
												...prevState,
												organization: e.target.value,
											}));
										}}
										id="organization"
										placeholder="Organization"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="title">
										Title
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewTraining((prevState: any) => ({
												...prevState,
												title: e.target.value,
											}));
										}}
										id="title"
										placeholder="Title"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="date">
										Date
									</FormLabel>
									<Input
										type="date"
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewTraining((prevState: any) => ({
												...prevState,
												date: e.target.value,
											}));
										}}
										id="date"
										placeholder="Date"
										required
									/>
								</Box>
								<Stack>
									<FormLabel fontWeight={700} htmlFor="objectives">
										Objectives
									</FormLabel>
									{objectives.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangeObjectives(index, e);
												setNewTraining((prevState: any) => ({
													...prevState,
													objectives,
												}));
											}}
											id="objective"
											placeholder="Add Objective"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddObjectives}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>
								<Box>
									<FormLabel fontWeight={700} htmlFor="venue">
										Venue
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewTraining((prevState: any) => ({
												...prevState,
												venue: e.target.value,
											}));
										}}
										id="venue"
										placeholder="Venue"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="state">
										State
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewTraining((prevState: any) => ({
												...prevState,
												state: e.target.value,
											}));
										}}
										id="state"
										placeholder="State"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="venue">
										Approach
									</FormLabel>
									<Select
										id="approach"
										onChange={(e) => {
											setNewTraining((prevState: any) => ({
												...prevState,
												approach: e.target.value,
											}));
										}}
									>
										<option value="">Select Approach</option>
										<option value="Virtual">Virtual</option>
										<option value="In-Person">In-Person</option>
									</Select>
								</Box>
								<Box>
									<FormLabel fontWeight={700}>Import Facilitators</FormLabel>
									<Input
										type="file"
										accept=".xlsx"
										border={'none'}
										onChange={handleFacilitatorFileChange}
									/>
									{facilitatorsData && (
										<Text fontWeight={700}>
											{facilitatorsData.length} Facilitators
										</Text>
									)}
								</Box>
								<Box>
									<FormLabel fontWeight={700}>Import Participants</FormLabel>
									<Input
										type="file"
										accept=".xlsx"
										border={'none'}
										onChange={handleParticipantFileChange}
									/>
									{participantsData && (
										<Text fontWeight={700}>
											{participantsData.length} Participants
										</Text>
									)}
								</Box>
							</Stack>

							<Flex
								mt="5"
								justifyContent="center"
								flexDir="column"
								as={DrawerFooter}
							>
								<Button
									w="100%"
									mb="2"
									borderColor="#00172D"
									_hover={{
										background: '#00172D',
										color: 'white',
									}}
									variant="outline"
									onClick={() => {
										onClose();
										clearArrayValues();
									}}
								>
									Cancel
								</Button>
								<Button
									isLoading={isSubmitting}
									color="white"
									w="100%"
									_hover={{
										border: '1px solid #00172D',
										bgColor: '#FFF',
										color: 'black',
									}}
									bgColor="#00172D"
									type="submit"
								>
									Add Training
								</Button>
							</Flex>
						</form>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
