import { Menu, MenuButton, IconButton, MenuList } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IAdmin } from '../interfaces/IAdmin';
import DeleteButtonDialog from './DeleteButtonDialog';
import EditUserDrawer from './EditUserDrawer';

interface AdminTableActionsButtonProps {
	admins: IAdmin[];
	adminId: string;
	setUserAdmins: React.Dispatch<React.SetStateAction<IAdmin[]>>;
}

export default function AdminTableActionsButton({
	adminId,
	admins,
	setUserAdmins,
}: AdminTableActionsButtonProps) {
	return (
		<Menu>
			<MenuButton
				borderColor="black"
				as={IconButton}
				aria-label="Options"
				icon={<BsThreeDotsVertical />}
				variant="outline"
			/>
			<MenuList>
				<EditUserDrawer userType="Admin" userId={adminId} userList={admins} />
				<DeleteButtonDialog
					userType="Admin"
					userId={adminId}
					userList={admins}
					setUserList={setUserAdmins}
				/>
			</MenuList>
		</Menu>
	);
}
