/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState } from 'react';
import instance from '../api/api';
import useSWR from 'swr';
import LoadingSpinner from './LoadingSpinner';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { BiBody } from 'react-icons/bi';
import UserSummaryCard from './UserSummaryCard';
import { IParticipants } from '../interfaces/IParticipants';
import AllParticipantsTable from './AllParticipantsTable';
import AllFacilitatorsTable from './AllFacilitatorsTable';
import { IFacilitator } from '../interfaces/IFacilitator';

interface IndividualTrainingInfoProps {
	trainingId: string;
	title: string;
}
export default function IndividualTrainingInfo({
	trainingId,
	title,
}: IndividualTrainingInfoProps) {
	/**
	 * TODO:
	 * Have total facilitators
	 * Have total participants
	 * Have facilitators table
	 * Have participants table
	 */

	const [numberOfParticipants, setNumberOfParticipants] = useState(0);
	const [numberOfFacilitators, setNumberOfFacilitators] = useState(0);
	const [participants, setParticipants] = useState<IParticipants[]>([]);
	const [facilitators, setFacilitators] = useState<IFacilitator[]>([]);

	const getParticipants = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setParticipants(response.data.participants);
		}

		return response.data;
	};

	const getFacilitators = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setFacilitators(response.data.facilitators);
		}

		return response.data;
	};

	const { data: participantsData, error: participantsError } = useSWR(
		`/api/v1/participants/training/${trainingId}`,
		getParticipants
	);

	const { data: facilitatorsData, error: facilitatorsError } = useSWR(
		`/api/v1/facilitators/training/${trainingId}`,
		getFacilitators
	);

	const getTrainingInfo = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setNumberOfParticipants(response.data.participants);
			setNumberOfFacilitators(response.data.facilitators);
		}

		return response.data;
	};

	const { data: trainingInformation, error: trainingInformationError } = useSWR(
		`/api/v1/trainings/training-info/${trainingId}`,
		getTrainingInfo
	);

	if (
		trainingInformationError ||
		!trainingInformation ||
		participantsError ||
		!participantsData ||
		facilitatorsError ||
		!facilitatorsData
	) {
		return <LoadingSpinner />;
	}
	console.log('this is the training id: ', trainingId);
	return (
		<Box w={'auto'}>
			<Heading textAlign={'center'}>{title}</Heading>
			<Flex p="12" flexDir={['column', 'row']} mb={['2', '2', '3']} gap="10px">
				<UserSummaryCard
					title="Participants"
					icon={BiBody}
					activeUsersCount={numberOfParticipants}
				/>
				<UserSummaryCard
					title="Facilitators"
					icon={BiBody}
					activeUsersCount={numberOfFacilitators}
				/>
			</Flex>
			<Stack>
				<Box border={'1px dotted black'} p={2}>
					<Text fontWeight={700} fontSize={'2em'} textAlign={'center'}>
						Participants
					</Text>
					<AllParticipantsTable
						participants={participants}
						setParticipants={setParticipants}
					/>
				</Box>
				<Box border={'1px dotted black'} p={2}>
					<Text fontWeight={700} fontSize={'2em'} textAlign={'center'}>
						Facilitators
					</Text>
					<AllFacilitatorsTable
						facilitators={facilitators}
						setFacilitators={setFacilitators}
					/>
				</Box>
			</Stack>
		</Box>
	);
}
