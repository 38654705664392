/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
	Box,
	Container,
	Flex,
	Heading,
	Hide,
	Icon,
	Stack,
	Text,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { BiWorld } from 'react-icons/bi';
import { FaCertificate } from 'react-icons/fa';
import { GiTeamIdea } from 'react-icons/gi';

interface CardProps {
	heading: string;
	description: string;
	icon: ReactElement;
	href?: string;
	alignment?: string | any | unknown;
}

const Card = ({ heading, description, icon, href, alignment }: CardProps) => {
	return (
		<Box
			maxW={{ base: 'full', md: '700px' }}
			w={'full'}
			borderWidth="1px"
			borderRadius="lg"
			overflow="hidden"
			p={5}
		>
			<Stack spacing={2}>
				<Flex
					w="auto"
					h={16}
					align={'right'}
					justify={alignment}
					color={'white'}
					rounded={'full'}
				>
					{icon}
				</Flex>
				<Box mt={2} textAlign={alignment}>
					<Heading size="md">{heading}</Heading>
					<Text mt={1} fontSize={'sm'}>
						{description}
					</Text>
				</Box>
			</Stack>
		</Box>
	);
};

export default function FeaturesSection() {
	return (
		<Box p={4}>
			<Hide>
				<Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
					<Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
						Short heading
					</Heading>
					<Hide>
						<Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
							Lorem ipsum dolor sit amet consectetur adipisicing elit.
							Reiciendis obcaecati ut cupiditate pariatur, dignissimos, placeat
							amet officiis.
						</Text>
					</Hide>
				</Stack>
			</Hide>

			<Container maxW={'10xl'} mt={12} py={10}>
				<Flex flexWrap="wrap" gridGap={6} justify="center">
					<Card
						heading={'Enhance'}
						icon={
							<Icon as={BsFillPersonLinesFill} w={10} h={10} color={'blue'} />
						}
						description={
							'Enhance your knowledge and skills in delivering quality healthcare services'
						}
						href={'#'}
						alignment={'left'}
					/>
					<Card
						heading={'Up-to-date'}
						icon={<Icon as={BiWorld} w={10} h={10} color={'green'} />}
						description={
							'Access up-to-date information and best practices in your respective field'
						}
						href={'#'}
						alignment={'right'}
					/>
					<Card
						heading={'Recognized certification'}
						icon={<Icon as={FaCertificate} w={10} h={10} color={'orange'} />}
						description={
							'Network and collaborate with other professionals in your field'
						}
						href={'#'}
						alignment={'left'}
					/>
					<Card
						heading={'Teamwork & networking'}
						icon={<Icon as={GiTeamIdea} w={10} h={10} color={'red'} />}
						description={
							'Improve your professional development and career prospects'
						}
						href={'#'}
						alignment={'right'}
					/>
				</Flex>
			</Container>
		</Box>
	);
}
