/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SyntheticEvent, useEffect, useState, useMemo } from 'react';
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Link,
	Image,
	Hide,
	Stack,
	FormControl,
	FormLabel,
	useToast,
	InputGroup,
	Select,
	Tabs,
	Tab,
	TabList,
	TabPanels,
	TabPanel,
	InputLeftElement,
} from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import axios from 'axios';
import validateEmail from '../constants/validateEmail';
import validatePhoneNumber from '../constants/validatePhoneNumber';
import {
	AiFillIdcard,
	AiFillPhone,
	AiOutlineMail,
	AiTwotoneHome,
} from 'react-icons/ai';
import {
	BsCalendarFill,
	BsFillEyeFill,
	BsFillEyeSlashFill,
	BsPersonFill,
} from 'react-icons/bs';
import { IStudent } from '../interfaces/IStudent';
import instance from '../api/api';
import validateNames from '../constants/validate';
import IModule from '../interfaces/IModule';
import countryList from 'react-select-country-list';
import { FaCertificate, FaGraduationCap, FaSchool } from 'react-icons/fa';

const validate = validateNames();

export default function Apply() {
	const [passwordShown, setPasswordShown] = useState(false);
	const [otherFacilitySelected, setOtherFacilitySelected] = useState(false);
	const navigate = useNavigate();
	const toast = useToast();
	const allCountries = useMemo(() => countryList().getData(), []);

	const [modules, setModules] = useState<IModule[]>([]);
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const handleNextTab = () => {
		setActiveTabIndex((prevIndex) => prevIndex + 1);
	};

	const handlePreviousTab = () => {
		setActiveTabIndex((prevIndex) => prevIndex - 1);
	};

	const handleOtherFacilitySelected = (e: any) => {
		setOtherFacilitySelected(false);
		if (e.target.value == 'Other') {
			setOtherFacilitySelected(true);
		}
	};

	const getModules = async (url: string) => {
		const response = await instance.get(url);
		if (response.data) setModules(response.data.modules);
	};

	useSWR('/api/v1/modules', getModules);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<IStudent>();

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const [formErrors, setFormErrors] = useState({
		firstname: '',
		lastname: '',
		emailAddress: '',
		module: '',
		password: '',
		phoneNumber: '',
		gender: '',
		dateOfBirth: '',
		nationalIdentificationNumber: '',
		address: '',
		country: '',
		// Academic qualifications
		graduationYear: '',
		schoolAttended: '',
		countryOfSchoolAttended: '',
		qualification: '',
		qualificationYear: '',
		// Experience
		profession: '',
		currentlyPracticing: '',
		clinicalPracticeDuration: '',
		facility: '',
	});

	const onSubmit = async (data: IStudent) => {
		try {
			if (data.country.toLowerCase() !== 'south sudan') {
				localStorage.setItem(
					'error1',
					'Applicants outside of South Sudan are ineligible for the short courses'
				);
			}
			const thisYear = new Date().getFullYear();
			const yearsApart = thisYear - data.graduationYear;
			if (yearsApart <= 1) {
				localStorage.setItem(
					'error2',
					'Your graduation year is less than one year from today.'
				);
			}
			if (data.currentlyPracticing == 'false') {
				localStorage.setItem(
					'error3',
					'Applicants who are not currently practicing are ineligible for the short courses'
				);
			}

			if (Number(data.clinicalPracticeDuration) == 1) {
				localStorage.setItem(
					'error4',
					'Applicants who have been practicing for less than one year are ineligible'
				);
			}
			const response = await instance.post('/api/v1/students/register', data);
			if (response) {
				console.log(response, data);
				const error1 = localStorage.getItem('error1');
				const error2 = localStorage.getItem('error2');
				const error3 = localStorage.getItem('error3');
				const error4 = localStorage.getItem('error4');

				if (error1 || error2 || error3 || error4) {
					navigate('/ineligible');
				} else {
					navigate('/success');
				}
			}
			if (!response) {
				toast({
					title: 'APPLICATION FAILED',
					description:
						'Sorry your application was not successful. Please try again later.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
			return response.data;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				toast({
					title: 'APPLICATION FAILED',
					description:
						'Sorry your application was not successful. Please try again later.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
				return error.message;
			}
			return 'An unexpected error occurred';
		}
	};

	const uploadError = {
		firstName: {
			required: 'First name is required',
			minLength: 3,
			// pattern: {
			// 	value: validate,
			// 	message: 'Invalid character!!',
			// },
		},
		surname: {
			required: 'Last name is required',
			minLength: 3,
			pattern: {
				value: validate,
				message: 'Invalid character!!',
			},
		},
		emailAddress: {
			required: 'Email address is required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email address',
			},
		},
		module: {
			required: 'Module is required',
		},
		password: {
			required: 'Please enter a password',
			minLength: 6,
		},
		phoneNumber: {
			required: 'Please enter a phone number',
			minLength: 6,
		},
		gender: {
			required: 'Please select a gender',
		},
		dateOfBirth: {
			required: 'Please select a date of birth',
		},
		nationalIdentificationNumber: {
			required: 'Please enter a national identification number',
		},
		address: {
			required: 'Please enter an address',
		},
		country: {
			required: 'Please select a country',
		},
		// Academic qualifications
		graduationYear: {
			required: 'Please select a graduation year',
			minLength: 4,
			maxLength: 4,
		},
		schoolAttended: {
			required: 'Please select a school attended',
		},
		countryOfSchoolAttended: {
			required: 'Please select a country of school attended',
		},
		qualification: {
			required: 'Please select a qualification',
		},
		qualificationYear: {
			required: 'Please select a qualification year',
			minLength: 4,
			maxLength: 4,
		},
		profession: {
			required: 'Please provide a profession',
		},
		// Experience
		currentlyPracticing: {
			required: 'Please indicate whether you are currently practicing',
		},
		clinicalPracticeDuration: {
			required: 'Please select a clinical practice duration',
		},
		facility: {
			required: 'Please select a facility',
		},
	};

	return (
		<div>
			<Navbar />
			<Stack h="100vh" align={'center'}>
				<Box w={[300, 400, 500]} textAlign="center" height="100px">
					<Text
						color="black"
						fontSize={{ base: '3xl', md: '5xl' }}
						fontWeight={400}
					>
						Application Portal
					</Text>
					<Hide>
						<Text>Let&apos;s log you into your account</Text>
					</Hide>
				</Box>
				<Tabs
					isFitted
					variant="enclosed"
					w={'80%'}
					mt="1em"
					border={'1px solid black'}
					borderRadius={'8px'}
					onChange={(index) => setActiveTabIndex(index)}
					index={activeTabIndex}
				>
					<TabList mb="1em">
						<Tab>Bio Data</Tab>
						<Tab>Academic Qualifications</Tab>
						<Tab>Experience</Tab>
						<Tab>Applicant{" ' "}s Declaration</Tab>
					</TabList>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<TabPanels>
							<TabPanel>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										First name
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<BsPersonFill />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="First Name"
											required
											{...register('firstName', uploadError.firstName)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.firstName && (
										<p className="error">{errors.firstName.message}</p>
									)}
									{errors?.firstName?.type === 'minLength' && (
										<p className="error">
											First name must be at least 3 characters long
										</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Surname
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<BsPersonFill />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="Surname"
											required
											{...register('surname', uploadError.surname)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.surname && (
										<p className="error">{errors.surname.message}</p>
									)}
									{errors?.surname?.type === 'minLength' && (
										<p className="error">
											Last name must be at least 3 characters long
										</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Gender
									</FormLabel>
									<Select
										id="gender"
										{...register('gender', uploadError.gender)}
									>
										<option value="">Select Gender</option>
										<option key="male" value="male">
											Male
										</option>
										<option key="female" value="female">
											Female
										</option>
									</Select>
									{errors?.gender && (
										<p className="error">{errors.gender.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Date of birth
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<BsCalendarFill />
										</InputLeftElement>
										<Input
											type="Date"
											borderColor="#D9D9D9"
											placeholder="Date Of Birth"
											required
											{...register('dateOfBirth', uploadError.dateOfBirth)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.dateOfBirth && (
										<p className="error">{errors.dateOfBirth.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Email address
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<AiOutlineMail />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="Email Address"
											required
											{...register('emailAddress', uploadError.emailAddress)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.emailAddress && (
										<p className="error">{errors.emailAddress.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Phone number
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<AiFillPhone />
										</InputLeftElement>
										<Input
											type="number"
											borderColor="#D9D9D9"
											placeholder="Phone Number"
											required
											{...register('phoneNumber', uploadError.phoneNumber)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.phoneNumber && (
										<p className="error">{errors.phoneNumber.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										National Identification Number
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<AiFillIdcard />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="National Identification Number"
											required
											{...register(
												'nationalIdentificationNumber',
												uploadError.nationalIdentificationNumber
											)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.nationalIdentificationNumber && (
										<p className="error">
											{errors.nationalIdentificationNumber.message}
										</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Address
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<AiTwotoneHome />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="Address"
											required
											{...register('address', uploadError.address)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.address && (
										<p className="error">{errors.address.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Country
									</FormLabel>
									<Select
										id="country"
										{...register('country', uploadError.country)}
									>
										<option value="">Select Country</option>
										{allCountries.map((option) => (
											<option key={option.value} value={option.label}>
												{option.label}
											</option>
										))}
									</Select>
									{errors?.country && (
										<p className="error">{errors.country.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Cadre
									</FormLabel>
									<Select
										id="module"
										{...register('module', uploadError.module)}
									>
										<option value="">Select Cadre</option>
										{modules.map((option) => (
											<option key={option._id} value={option._id}>
												{option.name}
											</option>
										))}
									</Select>
									{errors?.module && (
										<p className="error">{errors.module.message}</p>
									)}
								</FormControl>
								<Button
									float={'right'}
									alignContent="right"
									fontSize="xl"
									color="white"
									mb="1em"
									onClick={handleNextTab}
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									Next
								</Button>
							</TabPanel>
							<TabPanel>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Graduation year
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<FaGraduationCap />
										</InputLeftElement>
										<Input
											type="number"
											borderColor="#D9D9D9"
											placeholder="Graduation Year"
											required
											{...register(
												'graduationYear',
												uploadError.graduationYear
											)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.graduationYear && (
										<p className="error">{errors.graduationYear.message}</p>
									)}
									{errors?.graduationYear?.type === 'minLength' && (
										<p className="error">Invalid Year!</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										School attended
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<FaSchool />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="School Attended"
											required
											{...register(
												'schoolAttended',
												uploadError.schoolAttended
											)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.schoolAttended && (
										<p className="error">{errors.schoolAttended.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Country of school attended
									</FormLabel>
									<Select
										id="countryOfSchoolAttended"
										{...register(
											'countryOfSchoolAttended',
											uploadError.countryOfSchoolAttended
										)}
									>
										<option value="">Select Country</option>
										{allCountries.map((option) => (
											<option key={option.value} value={option.label}>
												{option.label}
											</option>
										))}
									</Select>
									{errors?.countryOfSchoolAttended && (
										<p className="error">
											{errors.countryOfSchoolAttended.message}
										</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Qualification
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<FaCertificate />
										</InputLeftElement>
										<Input
											borderColor="#D9D9D9"
											placeholder="Qualification"
											required
											{...register('qualification', uploadError.qualification)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.qualification && (
										<p className="error">{errors.qualification.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Qualification year
									</FormLabel>
									<InputGroup>
										<InputLeftElement pointerEvents="none">
											<FaCertificate />
										</InputLeftElement>
										<Input
											type="number"
											borderColor="#D9D9D9"
											placeholder="Qualification Year"
											required
											{...register(
												'qualificationYear',
												uploadError.qualificationYear
											)}
											mb="10px"
											fontWeight={200}
										/>
									</InputGroup>
									{errors?.qualificationYear && (
										<p className="error">{errors.qualificationYear.message}</p>
									)}
									{errors?.qualificationYear?.type === 'minLength' && (
										<p className="error">Invalid Year!</p>
									)}
								</FormControl>
								<Button
									float={'left'}
									alignContent="left"
									fontSize="xl"
									color="white"
									mb="1em"
									onClick={handlePreviousTab}
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									{'< '}Prev
								</Button>
								<Button
									float={'right'}
									alignContent="right"
									fontSize="xl"
									color="white"
									mb="1em"
									onClick={handleNextTab}
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									Next {' >'}
								</Button>
							</TabPanel>
							<TabPanel>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Profession
									</FormLabel>
									<Input
										borderColor="#D9D9D9"
										placeholder="Profession"
										required
										{...register('profession', uploadError.profession)}
										mb="10px"
										fontWeight={200}
									/>
									{errors?.profession && (
										<p className="error">{errors.profession.message}</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Are you currently practicing?
									</FormLabel>
									<Select
										id="currentlyPracticing"
										{...register(
											'currentlyPracticing',
											uploadError.currentlyPracticing
										)}
									>
										<option value="">Select option</option>
										<option key="true" value="true">
											Yes
										</option>
										<option key="false" value="false">
											No
										</option>
									</Select>
									{errors?.currentlyPracticing && (
										<p className="error">
											{errors.currentlyPracticing.message}
										</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										How long have you been practicing
									</FormLabel>
									<Select
										id="clinicalPracticeDuration"
										{...register(
											'clinicalPracticeDuration',
											uploadError.clinicalPracticeDuration
										)}
									>
										<option value="">Select duration</option>
										<option key="1" value="1">
											0 - 1 year
										</option>
										<option key="2" value="2">
											2 - 5 years
										</option>
										<option key="5" value="5">
											5+ years
										</option>
									</Select>
									{errors?.clinicalPracticeDuration && (
										<p className="error">
											{errors.clinicalPracticeDuration.message}
										</p>
									)}
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight={600} className="label">
										Facility
									</FormLabel>
									<Select
										id="facility"
										{...register('facility', uploadError.facility)}
										onChange={handleOtherFacilitySelected}
									>
										<option value="">Select facility</option>
										<option key="1" value="Test Facility">
											Test Facility
										</option>
										<option key="2" value="Other">
											Other
										</option>
									</Select>
									{errors?.facility && (
										<p className="error">{errors.facility.message}</p>
									)}
								</FormControl>
								{otherFacilitySelected && (
									<FormControl isRequired>
										<FormLabel fontWeight={600} className="label">
											Other Facility
										</FormLabel>
										<InputGroup>
											<InputLeftElement pointerEvents="none">
												<AiTwotoneHome />
											</InputLeftElement>
											<Input
												borderColor="#D9D9D9"
												placeholder="Other Facility"
												required
												{...register('facility', uploadError.facility)}
												mb="10px"
												fontWeight={200}
											/>
										</InputGroup>
										{errors?.facility && (
											<p className="error">{errors.facility.message}</p>
										)}
									</FormControl>
								)}
								<Button
									float={'left'}
									alignContent="left"
									fontSize="xl"
									color="white"
									mb="1em"
									onClick={handlePreviousTab}
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									{'< '}Prev
								</Button>
								<Button
									float={'right'}
									alignContent="right"
									fontSize="xl"
									color="white"
									mb="1em"
									onClick={handleNextTab}
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									Next {' >'}
								</Button>
							</TabPanel>
							<TabPanel>
								<Text fontWeight={700}>
									By submitting your application, you declare that you are
									committed to participating in the program with academic
									integrity and will avoid any form of plagiarism or academic
									malpractice. Any violation of academic integrity may result in
									disqualification from the program or revocation of any
									certification or degree awarded.
								</Text>
								<Button
									float={'left'}
									alignContent="left"
									fontSize="xl"
									color="white"
									mt="1em"
									mb="1em"
									onClick={handlePreviousTab}
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									{'< '}Prev
								</Button>
								<Button
									type="submit"
									float={'right'}
									alignContent="right"
									fontSize="xl"
									color="white"
									isLoading={isSubmitting}
									mb="1em"
									mt="1em"
									bgColor={'#00172D'}
									_hover={{
										border: '2px solid #00172D',
										color: 'black',
										bgColor: 'white',
									}}
								>
									Submit Application
								</Button>
							</TabPanel>
						</TabPanels>
					</form>
				</Tabs>
			</Stack>
		</div>
	);
}
