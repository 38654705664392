/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Menu, MenuButton, IconButton, MenuList } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IStudent } from '../interfaces/IStudent';
import EditUserDrawer from './EditUserDrawer';
import DeleteButtonDialog from './DeleteButtonDialog';
// import AddGradeModal from './AddGradeModal';
import { useState } from 'react';
import useSWR from 'swr';
import instance from '../api/api';
import ViewGradesModal from './ViewGradesModal';

interface StudentTableActionsButtonProps {
	students: IStudent[];
	studentId: string;
	setUserStudents: React.Dispatch<React.SetStateAction<IStudent[]>>;
}

export default function StudentTableActionsButton({
	studentId,
	students,
	setUserStudents,
}: StudentTableActionsButtonProps) {
	return (
		<Menu>
			<MenuButton
				borderColor="black"
				as={IconButton}
				aria-label="Options"
				icon={<BsThreeDotsVertical />}
				variant="outline"
			/>
			<MenuList>
				<EditUserDrawer
					userType="Student"
					userId={studentId}
					userList={students}
				/>
				<DeleteButtonDialog
					userType="Student"
					userId={studentId}
					userList={students}
					setUserList={setUserStudents}
				/>
				<ViewGradesModal userId={studentId} />
			</MenuList>
		</Menu>
	);
}
