/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Text,
	Box,
	MenuItem,
	Hide,
	useDisclosure,
	Table,
	TableCaption,
	TableContainer,
	Tbody,
	Td,
	Tfoot,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BiNotepad } from 'react-icons/bi';
import instance from '../api/api';
import useSWR from 'swr';
import LoadingSpinner from './LoadingSpinner';

interface GradesModalProps {
	userId: string;
	isOpen: boolean;
	onClose: () => void;
}
export default function ViewGradesModalForStudentPortal({
	userId,
	isOpen,
	onClose,
}: GradesModalProps) {
	const [grades, setGrades] = useState([
		{
			course_name: '',
			grade: 0,
		},
	]);

	const getGrades = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setGrades(response.data.grades);
		}
		return response.data.grades;
	};

	const { data: gradesData, error: gradesError } = useSWR(
		`/api/v1/grades/${userId}`,
		getGrades
	);

	if (!gradesData || gradesError) {
		return (
			<>
				<LoadingSpinner />
			</>
		);
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Grades</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<TableContainer>
							<Table variant="simple">
								<TableCaption>All grades</TableCaption>
								<Thead>
									<Tr>
										<Th>Course</Th>
										<Th>Grade</Th>
									</Tr>
								</Thead>
								<Tbody>
									{gradesData.map((grade: any, index: number) => (
										<Tr key={index}>
											<Td>{grade.course_name}</Td>
											<Td>{grade.grade}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
