import { Menu, MenuButton, IconButton, MenuList } from '@chakra-ui/react';
import { IFacilitator } from '../interfaces/IFacilitator';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DeleteButtonDialog from './DeleteButtonDialog';

interface FacilitatorsTableActionButtonProps {
	facilitators: IFacilitator[];
	facilitatorId: string;
	setFacilitators: React.Dispatch<React.SetStateAction<IFacilitator[]>>;
}

export default function FacilitatorsTableActionButton({
	facilitatorId,
	facilitators,
	setFacilitators,
}: FacilitatorsTableActionButtonProps) {
	return (
		<Menu>
			<MenuButton
				borderColor="black"
				as={IconButton}
				aria-label="Options"
				icon={<BsThreeDotsVertical />}
				variant="outline"
			/>
			<MenuList>
				{/* <EditUserDrawer userType="Facilitator" userId={facilitatorId} userList={facilitators} /> */}
				<DeleteButtonDialog
					userType="Admin"
					userId={facilitatorId}
					userList={facilitators}
					setUserList={setFacilitators}
				/>
			</MenuList>
		</Menu>
	);
}
