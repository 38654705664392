/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Hide,
	Text,
	MenuItem,
	useToast,
	Box,
	Input,
	Table,
	Tbody,
	Td,
	Tr,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { IStudent } from '../interfaces/IStudent';
import axios from 'axios';
import useSWR from 'swr';
import instance from '../api/api';
import LoadingSpinner from './LoadingSpinner';
import { IGrade } from '../interfaces/IGrade';
import { BiNotepad } from 'react-icons/bi';

interface GradeModalProps {
	courseId: string;
	moduleId: any;
	topic: string;
	allStudents: any;
}

interface StudentGradeProps {
	student_id: string;
	course_id: string;
	score: number;
}
export default function AddGradeModal({
	courseId,
	moduleId,
	topic,
	allStudents,
}: GradeModalProps) {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const studentsGrades: any[] = [];

	// const getAllGradesForOneCourse = async (url: string) => {
	// 	const response = await instance.get(url);

	// 	if (response.data) {
	// 		// console.log(
	// 		// 	'All grades for one course found: ',
	// 		// 	response.data.courseGrades
	// 		// );
	// 		setCourseGrades(response.data.courseGrades);
	// 	}
	// 	return response.data;
	// };

	// const { data: courseGradesData, error: courseGradesError } = useSWR(
	// 	`/api/v1/grades/course/${courseId}`,
	// 	getAllGradesForOneCourse
	// );

	if (!allStudents) {
		return (
			<div>
				<Button
					data-testid="addGradeButton"
					name="add-grade"
					role="button"
					h={[26, 26, 26]}
					fontSize={14}
					mr={5}
					w="auto"
					borderRadius={6}
					_hover={{
						bgColor: 'white',
						color: 'black',
						border: '2px solid #00172D',
					}}
					bgColor="#00172D"
					border="2px solid #00172D"
					color="white"
					px={{ base: 4, md: 8 }}
					onClick={onOpen}
				>
					<span>
						<BiNotepad />
					</span>{' '}
					Add Grades
				</Button>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Edit Grades for ...</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<LoadingSpinner />
						</ModalBody>

						<ModalFooter>
							<Button colorScheme="blue" mr={3} onClick={onClose}>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
					<LoadingSpinner />
				</Modal>
			</div>
		);
	}
	const addToGrades = (studentId: string, score: number) => {
		const grade = {
			student_id: studentId,
			course_id: courseId,
			score,
		};

		const existingIndex = studentsGrades.findIndex(
			(item) => item.student_id === studentId
		);

		if (existingIndex !== -1) {
			studentsGrades.splice(existingIndex, 1);
		}
		studentsGrades.push(grade);
		console.log(studentsGrades);
	};

	const handleNumberChange = (
		index: number,
		studentId: string,
		value: number
	) => {
		addToGrades(studentId, value);
	};

	const onSubmit = async () => {
		try {
			for (const grade of studentsGrades) {
				const newGrade = {
					student_id: grade.student_id,
					course_id: grade.course_id,
					grade: grade.score,
				};

				await instance.post('/api/v1/grades/add-grade', newGrade);
			}
			toast({
				title: 'Grades updated',
				description: "Student's grades updated successfully",
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			// return response;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response) {
					toast({
						title: 'Grades not Updated',
						description: 'Grades could not be updated ',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
					return error;
				}
			}
			return 'An unexpected error occurred';
		}
	};

	return (
		<>
			<Button
				data-testid="addGradeButton"
				name="add-grade"
				role="button"
				h={[26, 26, 26]}
				fontSize={14}
				mr={5}
				w="auto"
				borderRadius={6}
				_hover={{
					bgColor: 'white',
					color: 'black',
					border: '2px solid #00172D',
				}}
				bgColor="#00172D"
				border="2px solid #00172D"
				color="white"
				px={{ base: 4, md: 8 }}
				onClick={onOpen}
			>
				<span>
					<BiNotepad />
				</span>{' '}
				Add Grades
			</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>Add Grades for {topic}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Table variant="simple">
							<Tbody>
								{allStudents.map((student: IStudent, index: number) => (
									<Tr key={index}>
										<Td>
											{student.firstName} {student.surname}
										</Td>
										<Td>
											<Input
												border={'1px solid black'}
												type="number"
												onChange={(e) =>
													handleNumberChange(
														index,
														student._id,
														parseInt(e.target.value, 10)
													)
												}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</ModalBody>

					<ModalFooter>
						{/* <Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button> */}
						<Button
							bg="#00172D"
							color="white"
							_hover={{
								bgColor: 'white',
								color: 'black',
								border: '2px solid #00172D',
							}}
							onClick={() => {
								onSubmit();
								onClose();
							}}
						>
							Add Grades
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
