/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import {
	Text,
	Flex,
	Table,
	Tbody,
	Td,
	Tr,
	Thead,
	Box,
	Th,
	IconButton,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Tooltip,
	Badge,
} from '@chakra-ui/react';
import {
	ArrowLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ArrowRightIcon,
} from '@chakra-ui/icons';
import { matchSorter } from 'match-sorter';
import {
	useTable,
	usePagination,
	useFilters,
	useGlobalFilter,
} from 'react-table';
import moment from 'moment';
// import AdminTableActionsButton from './AdminTableActionsButton';
import { IStudent } from '../interfaces/IStudent';
import DefaultColumnFilter from './DefaultFilter';
import GlobalFilter from './GlobalFilter';
import STATUS from '../constants/status';
import useSWR from 'swr';
import StudentTableActionsButton from './StudentTableActionsButton';
import IModule from '../interfaces/IModule';
import instance from '../api/api';

moment().format();

interface AllAdminsTableProps {
	students: IStudent[];
	setUserStudents: React.Dispatch<React.SetStateAction<IStudent[]>>;
}

export function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
	return matchSorter(rows, filterValue, {
		keys: [(row: any) => row.values[id]],
	});
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

function CustomTable({ columns, data }: any) {
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows: any, id: any, filterValue: any) => {
				return rows.filter((row: any) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		// @ts-ignore
		preGlobalFilteredRows,
		// @ts-ignore
		setGlobalFilter,
		headerGroups,
		prepareRow,
		// @ts-ignore
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		// @ts-ignore
		canPreviousPage,
		visibleColumns,
		state,
		// @ts-ignore
		canNextPage,
		// @ts-ignore
		pageOptions,
		// @ts-ignore
		pageCount,
		// @ts-ignore
		gotoPage,
		// @ts-ignore
		nextPage,
		// @ts-ignore
		previousPage,
		// @ts-ignore
		setPageSize,
		// @ts-ignore

		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
		},
		useFilters, // useFilters!
		useGlobalFilter, // useGlobalFilter!
		usePagination
	);

	// Render the UI for your table
	return (
		<>
			<Table w="100%" size={['sm', 'md']} {...getTableProps()}>
				<Thead>
					<Tr>
						<Th
							colSpan={visibleColumns.length}
							style={{
								textAlign: 'left',
							}}
						>
							<GlobalFilter
								preGlobalFilteredRows={preGlobalFilteredRows}
								// @ts-ignore
								globalFilter={state.globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>
						</Th>
					</Tr>
					{headerGroups.map((headerGroup) => (
						<Tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row: any) => {
						prepareRow(row);
						return (
							<Tr bg="#EEEEEF" {...row.getRowProps()}>
								{row.cells.map((cell: any) => {
									return (
										<Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>

			<Flex m={4} justifyContent={['flex-start', 'flex-start', 'center']}>
				<Flex>
					<Tooltip label="First Page">
						<IconButton
							onClick={() => gotoPage(0)}
							isDisabled={!canPreviousPage}
							icon={<ArrowLeftIcon h={3} w={3} />}
							mr={4}
							aria-label=""
						/>
					</Tooltip>
					<Tooltip label="Previous Page">
						<IconButton
							onClick={previousPage}
							isDisabled={!canPreviousPage}
							icon={<ChevronLeftIcon h={6} w={6} />}
							aria-label=""
						/>
					</Tooltip>
				</Flex>

				<Flex alignItems="center">
					<Text fontSize={['xs', 'md']} flexShrink="0" mr={8}>
						Page{' '}
						<Text fontWeight="bold" as="span">
							{pageIndex + 1}
						</Text>{' '}
						of{' '}
						<Text fontWeight="bold" as="span">
							{pageOptions.length}
						</Text>
					</Text>
					<Text fontSize={['xs', 'md']} flexShrink="0">
						Go to page:
					</Text>{' '}
					<NumberInput
						ml={2}
						mr={8}
						w={28}
						min={1}
						max={pageOptions.length}
						onChange={(value) => {
							// @ts-ignore
							const pageToGoto = value ? value - 1 : 0;
							gotoPage(pageToGoto);
						}}
						defaultValue={pageIndex + 1}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<Select
						w={32}
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
					>
						{[10, 20, 30, 40, 50].map((pageSizeAmount) => (
							<option key={pageSizeAmount} value={pageSizeAmount}>
								Show {pageSizeAmount}
							</option>
						))}
					</Select>
				</Flex>

				<Flex>
					<Tooltip label="Next Page">
						<IconButton
							onClick={nextPage}
							isDisabled={!canNextPage}
							icon={<ChevronRightIcon h={6} w={6} />}
							aria-label=""
						/>
					</Tooltip>
					<Tooltip label="Last Page">
						<IconButton
							onClick={() => gotoPage(pageCount - 1)}
							isDisabled={!canNextPage}
							icon={<ArrowRightIcon h={3} w={3} />}
							ml={4}
							aria-label=""
						/>
					</Tooltip>
				</Flex>
			</Flex>
		</>
	);
}

function AllStudentsTable({ students, setUserStudents }: AllAdminsTableProps) {
	const [modules, setModules] = useState<IModule[]>([]);
	const sendRequest = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setModules(response.data.modules);
		}
		return response.data;
	};

	useSWR('/api/v1/modules', sendRequest);

	const getModuleName = (id: string) => {
		let foundName = '';
		for (const module of modules) {
			if (module._id === id) {
				foundName = module.name;
			}
		}
		return foundName;
	};
	const columns = React.useMemo(() => {
		return [
			{
				Header: ' ',
				columns: [
					{
						Header: 'Names',
						accessor: (row: any) => {
							return `${row.firstName} ${row.surname}`;
						},
					},
					{
						Header: 'Email Address',
						accessor: 'emailAddress',
					},
					{
						Header: 'Status',
						accessor: 'status',
						/* eslint-disable */
                        Cell: (props: any) => {
                          return props.value === STATUS.ENROLLED ? (
                            <Box borderRadius="md" color="white" bg="#606C38" as={Badge}>
                              Enrolled
                            </Box>
                          ) : props.value === STATUS.PENDING ? (
                            <Box borderRadius="md" color="white" bg="#303133" as={Badge}>
                              Pending
                            </Box>
                          ) : (
                            <Box borderRadius="md" color="white" bg="#303133" as={Badge}>
                              Suspended
                            </Box>
                          );
                        },
                      },
                    {
                        Header: 'Course',
                        accessor: (row: any) => {
							return `${getModuleName(row.module)}`;
						},
                    },
                    {
                        Header: "Date Requested",
                        accessor: (row: any) => {
                          return moment(row.createdAt).local().format("DD-MM-YYYY ");
                        },
                      },
							{
								Header: 'Actions',
								accessor: '_id',
								/* eslint-disable */
					Cell: (props: any) => {
					  return (
					    <StudentTableActionsButton
					      studentId={props.value}
					      students={students}
					      setUserStudents={setUserStudents}
					    />
					  );
					},
					/* eslint-enable */
					},
				],
			},
		];
	}, [students, setUserStudents]);

	return <CustomTable columns={columns} data={students} />;
}

export default AllStudentsTable;
