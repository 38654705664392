/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-extraneous-dependencies */
import {
	Flex,
	Heading,
	Stack,
	Box,
	Text,
	Link,
	UnorderedList,
	ListItem,
	Divider,
	useDisclosure,
	Button,
} from '@chakra-ui/react';
import { useState } from 'react';
// import Navbar from '../components/Navbar';
import WelcomeAlert from '../components/WelcomeAlert';
import { CProgressBar, CProgress } from '@coreui/react';
// import '@coreui/coreui/dist/css/coreui.min.css';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import StudentHeader from '../components/StudentHeader';
import { ICourse } from '../interfaces/ICourse';
import useSWR from 'swr';
import instance from '../api/api';
import LoadingSpinner from '../components/LoadingSpinner';
import GetMonthName from '../utils/GetMonthName';
import { BiNotepad } from 'react-icons/bi';
import { useNavigate } from 'react-router';
// type ValuePiece = Date | null;
import moment from 'moment';
import ViewGradesModal from '../components/ViewGradesModal';
import ViewGradesModalForStudentPortal from '../components/ViewGradesModalForStudentPortal';

moment().format();

interface MonthCardProps {
	month: string;
	data: ICourse[];
}

const MonthCard = ({ month, data }: MonthCardProps) => {
	const navigate = useNavigate();
	const newData = [];
	for (let i = 0; i < data.length; i++) {
		if (GetMonthName(new Date(data[i].date).getMonth()) == month) {
			newData.push({
				course: data[i],
				index: i,
			});
		}
	}
	return (
		<Box
			bgColor={'#FFFFFF'}
			p={3}
			borderRadius={15}
			textAlign={'left'}
			border={'1px solid black'}
		>
			<Heading>{month}</Heading>
			<Divider color="grey"></Divider>
			{newData.map((course) => (
				<Flex key={course.course._id}>
					<BiNotepad size={30} color="blue" />
					<Text>
						<Link
							fontSize={'xl'}
							key={course.course._id}
							href={'#'}
							onClick={() => {
								localStorage.setItem('courseId', course.course._id);
								localStorage.setItem(
									'courseIndex',
									course.index.toLocaleString()
								);
								localStorage.setItem('module', course.course.module.toString());
								navigate('/dashboard/course-description', {
									state: course.course._id,
								});
							}}
						>
							{course.course.topic}
						</Link>{' '}
						({moment(course.course.date).local().format('DD-MM-YYYY')}){' '}
						{'12:00noon - 1:30pm'}
					</Text>
				</Flex>
			))}
		</Box>
	);
};

export default function StudentPortal() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	// const [value, onChange] = useState<Value>(new Date());
	const moduleId = localStorage.getItem('module');
	const studentId = localStorage.getItem('userId');
	// const [enrollment, setEnrollment] = useState([]);
	const [moduleCourses, setModuleCourses] = useState([
		{
			_id: '',
			count: 0,
		},
	]);

	const [completedCoursesCount, setCompletedCoursesCount] = useState(0);
	const getStudentEnrollment = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setCompletedCoursesCount(
				response.data.enrollment.completed_courses.length
			);
		}
		return response.data.enrollment.completed_courses.length;
	};

	const { data: studentEnrollmentData, error: studentEnrollmentError } = useSWR(
		`/api/v1/enrollments/student-enrollment/${studentId?.toString()}`,
		getStudentEnrollment
	);

	const getModuleCourses = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			console.log(response.data);
			console.log(moduleId);
			setModuleCourses(response.data.moduleCourses);
		}
		return response.data;
	};

	const { data: moduleCoursesData, error: moduleCoursesError } = useSWR(
		'/api/v1/modules/module-courses',
		getModuleCourses
	);

	const getTotalCoursesForAModule = (mId: string | null) => {
		let totalCourses = 0;
		for (const module of moduleCourses) {
			if (module._id === mId) {
				totalCourses = module.count;
			}
		}
		return totalCourses;
	};

	const [monthNames, setMonthNames] = useState<string[]>([]);

	const [courses, setCourses] = useState<ICourse[]>([]);

	const sendRequest = async (url: string) => {
		const allCourses: ICourse[] = [];
		const response = await instance.get(url);

		if (response.data) {
			for (let i = 0; i < response.data.courses.length; i += 1) {
				allCourses.push(response.data.courses[i]);
			}
		}

		setCourses(allCourses);
		return response.data;
	};

	for (let i = 0; i < courses.length; i++) {
		const allMonths = [];
		const nameOfMonth: string = GetMonthName(
			new Date(courses[i].date).getMonth()
		);
		allMonths.push(nameOfMonth);

		if (!monthNames.includes(nameOfMonth)) {
			monthNames.push(nameOfMonth);
		}
	}

	const { data: coursesData, error: coursesError } = useSWR(
		'/api/v1/courses',
		sendRequest
		// { refreshInterval: 5000 }
	);

	const firstName = localStorage.getItem('firstName');

	if (
		!coursesData ||
		coursesError ||
		!studentEnrollmentData ||
		!moduleCoursesData
	) {
		return (
			<div>
				<StudentHeader />
				<LoadingSpinner />
			</div>
		);
	}
	return (
		<Stack>
			<StudentHeader />
			<Heading px={5}>Welcome, {firstName} </Heading>
			{/* <Box px={5}>
				<WelcomeAlert />
			</Box> */}
			<Flex flexDir={'row'} p={5} gap={5}>
				<Flex flex={1} borderRadius={15} flexDirection={'column'} maxH={'50vh'}>
					<Box bgColor={'#FFFFFF'} borderRadius={15}>
						<WelcomeAlert />
					</Box>
				</Flex>
				<Flex flex={3} p={3} borderRadius={15} gap={5} flexDirection={'column'}>
					{monthNames.map((month: string, index: number) => (
						<MonthCard key={index} month={month} data={courses} />
					))}
				</Flex>
				<Flex
					flex={1}
					p={3}
					borderRadius={15}
					flexDirection={'column'}
					gap={5}
					maxH={'50vh'}
				>
					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Stack>
							<Text fontWeight={700}>
								{completedCoursesCount}/{getTotalCoursesForAModule(moduleId)}{' '}
								Courses Completed
							</Text>
						</Stack>
						<CProgress className="mb-3">
							<CProgressBar
								value={Math.round(
									(completedCoursesCount /
										getTotalCoursesForAModule(moduleId)) *
										100
								)}
							>
								{Math.round(
									(completedCoursesCount /
										getTotalCoursesForAModule(moduleId)) *
										100
								)}
								%
							</CProgressBar>
						</CProgress>
					</Box>

					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						textAlign={'center'}
						border={'1px solid black'}
					>
						<Button
							onClick={onOpen}
							bgColor={'white'}
							color="blue"
							_hover={{
								bgColor: 'white',
							}}
						>
							View Grades
						</Button>
						<ViewGradesModalForStudentPortal
							isOpen={isOpen}
							onClose={onClose}
							userId={String(studentId)}
						/>
					</Box>
				</Flex>
			</Flex>
			{/* <Text fontWeight={600} fontSize={'xl'}>
						Schedule
					</Text>
					<Box p={3}>
						<Calendar onChange={onChange} value={value} />
					</Box> */}
		</Stack>
	);
}
