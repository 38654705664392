import WhyChooseUsImage from '../images/LandingPage/WhyChooseUsImage.svg';
import { Flex, Stack, Img, Text, Heading, Hide } from '@chakra-ui/react';
export default function WhyChooseUs() {
	return (
		<Flex
			p={10}
			flexDir={{ base: 'column', lg: 'row' }}
			justifyContent={{ base: 'center', lg: 'right' }}
		>
			<Hide>
				<Stack p={5}>
					<Img src={WhyChooseUsImage} borderRadius={20} />
				</Stack>
			</Hide>
			<Stack p={5} spacing={6} w={{ base: '100%', lg: '50%' }}>
				<Text fontWeight={700} color="blue">
					Why Choose Us
				</Text>

				<Heading fontSize={'3xl'} lineHeight={'2em'}>
					Building the capacities of frontline healthcare workers in delivering
					quality health services.
				</Heading>

				<Text lineHeight="1.7em" fontSize={'xl'}>
					Frontline healthcare workers play a crucial role in providing quality
					health services to patients. Building their capacities is essential to
					ensure that they are equipped with the necessary skills, knowledge,
					and confidence to deliver efficient and effective healthcare services.
				</Text>
			</Stack>
		</Flex>
	);
}
