/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
	Flex,
	Heading,
	Stack,
	Box,
	Text,
	Link,
	UnorderedList,
	ListItem,
	useDisclosure,
	useToast,
	Divider,
	Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import StudentHeader from '../components/StudentHeader';
import LoadingSpinner from '../components/LoadingSpinner';
import { CProgressBar, CProgress } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import useSWR from 'swr';
import instance from '../api/api';
import { ICourse } from '../interfaces/ICourse';
import ViewGradesModal from '../components/ViewGradesModal';

export const formatDate = (date: any) => {
	const newDate = new Date(date);

	const formattedDate = newDate.toLocaleString('en-US', {
		month: 'long',
		year: 'numeric',
		day: 'numeric',
		weekday: 'long',
	});

	return formattedDate;
};

export default function CourseDescription() {
	const courseId = localStorage.getItem('courseId');
	const studentId = localStorage.getItem('userId');
	const moduleId = localStorage.getItem('module');
	const toast = useToast();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [completedCourses, setCompletedCourses] = useState([]);
	const [foundCourse, setFoundCourse] = useState<ICourse>();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [completedCoursesCount, setCompletedCoursesCount] = useState(0);
	const courseIndex = Number(localStorage.getItem('courseIndex'));
	const fifthCourse = courseIndex == (4 || 8 || 12 || 16 || 20);
	const [isCourseCompleted, setIsCourseCompleted] = useState(false);
	const [moduleCourses, setModuleCourses] = useState([
		{
			_id: '',
			count: 0,
		},
	]);
	const getStudentEnrollment = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			for (const course of response.data.enrollment.completed_courses) {
				if (course == courseId) {
					console.log('we are inside here...');
					setIsCourseCompleted(true);
				}
			}
			setCompletedCoursesCount(
				response.data.enrollment.completed_courses.length
			);
			setCompletedCourses(response.data.enrollment.completed_courses);
		}
		return response.data;
	};

	const { data: studentEnrollmentData, error: studentEnrollmentError } = useSWR(
		`/api/v1/enrollments/student-enrollment/${studentId?.toString()}`,
		getStudentEnrollment
	);

	const getModuleCourses = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setModuleCourses(response.data.moduleCourses);
		}
		return response.data;
	};

	const { data: moduleCoursesData, error: moduleCoursesError } = useSWR(
		'/api/v1/modules/module-courses',
		getModuleCourses
	);

	const getTotalCoursesForAModule = (mId: string | null) => {
		let totalCourses = 0;
		for (const module of moduleCourses) {
			if (module._id === mId) {
				totalCourses = module.count;
			}
		}
		return totalCourses;
	};

	const sendRequest = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setFoundCourse(response.data.course);
		}
		return response.data;
	};

	const { data: courseData, error: courseError } = useSWR(
		`/api/v1/courses/${courseId}`,
		sendRequest
	);
	const firstName = localStorage.getItem('firstName');
	const formattedDate = formatDate(foundCourse?.date);

	const handleCompleteCourse = async () => {
		setIsSubmitting(true);
		try {
			await instance.put('/api/v1/enrollments/complete-course', {
				student_id: studentId,
				course_id: courseId,
			});
			toast({
				title: 'Course completed',
				description: 'Course completed successfully',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			setIsSubmitting(false);
		} catch (error) {
			toast({
				title: 'Error',
				description:
					'Failed to mark course as completed. Please try again later',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			setIsSubmitting(false);
		}
	};

	if (
		!courseData ||
		courseError ||
		!foundCourse ||
		!studentEnrollmentData ||
		studentEnrollmentError ||
		!moduleCoursesData ||
		moduleCoursesError
	) {
		return (
			<div>
				<StudentHeader />
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<Stack>
			<StudentHeader />
			<Heading px={5}>Welcome, {firstName} </Heading>
			<Flex flexDir={'row'} p={5} gap={5}>
				{/* <Flex
					flex={1}
					p={3}
					bgColor={'#F5F5DC'}
					borderRadius={15}
					flexDirection={'column'}
					gap={5}
					maxH={'50vh'}
				>
					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Stack>
							<Text fontWeight={700}>3/10 Courses Completed</Text>
						</Stack>
						<CProgress className="mb-3">
							<CProgressBar value={25}>30%</CProgressBar>
						</CProgress>
					</Box>

					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						textAlign={'center'}
						border={'1px solid black'}
					>
						<Link href="#" color="blue" onClick={onOpen}>
							View Grades
						</Link>
						<ViewGradesModal isOpen={isOpen} onClose={onClose} />
					</Box>
				</Flex> */}
				<Flex
					flex={3}
					p={3}
					// bgColor={'#F5F5DC'}
					borderRadius={15}
					gap={5}
					flexDirection={'column'}
				>
					<Stack
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						textAlign={'center'}
						flexDir={'row'}
						justifyContent={'space-between'}
						border={'1px solid black'}
					>
						<Heading>{foundCourse?.topic}</Heading>
						<Text color={'blue'} fontWeight={600}>
							{foundCourse?.time} Minutes
						</Text>
					</Stack>
					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						textAlign={'center'}
						border={'1px solid black'}
					>
						<Text fontWeight={700}>{formatDate(foundCourse?.date)}</Text>
					</Box>
					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Text fontWeight={700}>Learning Objectives: </Text>
						<UnorderedList px={10}>
							{foundCourse?.objectives.map(
								(objective: string, index: number) => (
									<ListItem key={index}>{objective}</ListItem>
								)
							)}
						</UnorderedList>
					</Box>

					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Text fontWeight={700}>Competencies</Text>
						<UnorderedList px={10}>
							{foundCourse?.competencies.map(
								(competence: string, index: number) => (
									<ListItem key={index}>{competence}</ListItem>
								)
							)}
						</UnorderedList>
					</Box>

					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Text fontWeight={700}>Presenters</Text>
						<UnorderedList px={10}>
							{foundCourse?.presenters.map(
								(presenter: string, index: number) => (
									<ListItem key={index}>{presenter}</ListItem>
								)
							)}
						</UnorderedList>
					</Box>

					<Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Text fontWeight={700}>Meeting Details</Text>
						<UnorderedList px={10}>
							<ListItem>
								Meeting Link:{' '}
								<Link color={'blue'} href={foundCourse?.meetingLink}>
									{foundCourse?.meetingLink}
								</Link>
							</ListItem>
							<ListItem>Meeting ID: {foundCourse?.meetingID}</ListItem>
							<ListItem>Password: {foundCourse?.password}</ListItem>
							<ListItem>
								End of Session Evaluation:{' '}
								<Link color={'blue'} href={foundCourse?.sessionEvaluationLink}>
									{foundCourse?.sessionEvaluationLink}
								</Link>
							</ListItem>
							{fifthCourse && (
								<ListItem>
									Formative Assessment:{' '}
									<Link
										color={'blue'}
										href={foundCourse?.formativeAssessmentLink}
									>
										{foundCourse?.formativeAssessmentLink}
									</Link>
								</ListItem>
							)}
						</UnorderedList>
					</Box>
				</Flex>
				<Flex
					flex={1}
					p={3}
					borderRadius={15}
					flexDirection={'column'}
					gap={5}
					maxH={'50vh'}
				>
					{!isCourseCompleted ? (
						<Button
							color="white"
							w="100%"
							isLoading={isSubmitting}
							_hover={{
								border: '1px solid #00172D',
								bgColor: '#FFF',
								color: 'black',
							}}
							bgColor="#00172D"
							onClick={handleCompleteCourse}
						>
							Mark As Complete
						</Button>
					) : (
						<Button
							isDisabled={true}
							color="white"
							w="100%"
							_hover={{
								border: '1px solid #00172D',
								bgColor: '#FFF',
								color: 'black',
							}}
							bgColor="#00172D"
						>
							Course Completed
						</Button>
					)}
					{/* <Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						border={'1px solid black'}
					>
						<Stack>
							<Text fontWeight={700}>
								{completedCoursesCount}/{getTotalCoursesForAModule(moduleId)}{' '}
								Courses Completed
							</Text>
						</Stack>
						<CProgress className="mb=3">
							<CProgressBar
								value={Math.round(
									(completedCoursesCount /
										getTotalCoursesForAModule(moduleId)) *
										100
								)}
							>
								{Math.round(
									(completedCoursesCount /
										getTotalCoursesForAModule(moduleId)) *
										100
								)}
								%
							</CProgressBar>
						</CProgress>
					</Box> */}

					{/* <Box
						bgColor={'#FFFFFF'}
						p={3}
						borderRadius={15}
						textAlign={'center'}
						border={'1px solid black'}
					>
						<Link href="#" color="blue" onClick={onOpen}>
							View Grades
						</Link>
						<ViewGradesModal userId={String(studentId)} />
					</Box> */}
				</Flex>
			</Flex>
		</Stack>
	);
}
