/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	ArrowLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ArrowRightIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from '@chakra-ui/icons';
import {
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Box,
	Td,
	Text,
	Flex,
	IconButton,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Select,
	Tooltip,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Collapse,
} from '@chakra-ui/react';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
} from 'react-table';
import { ITraining } from '../interfaces/ITraining';
import DefaultColumnFilter from './DefaultFilter';
import GlobalFilter from './GlobalFilter';
import { IParticipants } from '../interfaces/IParticipants';
import instance from '../api/api';
import useSWR from 'swr';
import IndividualTrainingInfo from './IndividualTrainingInfo';

moment().format();

interface AllTrainingsTableProps {
	trainings: ITraining[];
	setTrainings: React.Dispatch<React.SetStateAction<ITraining[]>>;
}

export function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
	return matchSorter(rows, filterValue, {
		keys: [(row: any) => row.values[id]],
	});
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

function CustomTable({ columns, data }: any) {
	const [participants, setParticipants] = useState<IParticipants[]>([]);

	const getPartipants = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			setParticipants(response.data.participants);
		}
		return response.data.participants;
	};

	const [openRows, setOpenRows] = useState<any[]>([]);

	const toggleRow = (index: any, row: any) => {
		console.log(row.original._id);
		// useSWR(`/api/v1/participants/training/${row.original._id}`, getPartipants);
		console.log(participants);
		if (openRows.includes(index)) {
			setOpenRows(openRows.filter((i) => i !== index));
		} else {
			setOpenRows([...openRows, index]);
		}
	};
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows: any, id: any, filterValue: any) => {
				return rows.filter((row: any) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		// @ts-ignore
		preGlobalFilteredRows,
		// @ts-ignore
		setGlobalFilter,
		headerGroups,
		prepareRow,
		// @ts-ignore
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		// @ts-ignore
		canPreviousPage,
		visibleColumns,
		state,
		// @ts-ignore
		canNextPage,
		// @ts-ignore
		pageOptions,
		// @ts-ignore
		pageCount,
		// @ts-ignore
		gotoPage,
		// @ts-ignore
		nextPage,
		// @ts-ignore
		previousPage,
		// @ts-ignore
		setPageSize,
		// @ts-ignore

		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
		},
		useFilters, // useFilters!
		useGlobalFilter, // useGlobalFilter!
		usePagination
	);

	// Render the UI for your table
	return (
		<>
			<Table w="100%" size={['sm', 'md']} {...getTableProps()}>
				<Thead>
					<Tr>
						<Th
							colSpan={visibleColumns.length}
							style={{
								textAlign: 'left',
							}}
						>
							<GlobalFilter
								preGlobalFilteredRows={preGlobalFilteredRows}
								// @ts-ignore
								globalFilter={state.globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>
						</Th>
					</Tr>
					{headerGroups.map((headerGroup) => (
						<Tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
							))}
						</Tr>
					))}
				</Thead>

				<Tbody {...getTableBodyProps()}>
					{/* <Accordion>
						<AccordionItem>
							<AccordionButton> */}
					{page.map((row: any, index: number) => {
						prepareRow(row);
						return (
							<React.Fragment key={index}>
								<Tr bg="#EEEEEF" {...row.getRowProps()}>
									{row.cells.map((cell: any) => {
										return (
											<Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
										);
									})}
									<Td>
										<IconButton
											icon={
												openRows.includes(index) ? (
													<ChevronUpIcon />
												) : (
													<ChevronDownIcon />
												)
											}
											onClick={() => {
												toggleRow(index, row);
											}}
											aria-label={''}
										/>
									</Td>
								</Tr>
								<Tr>
									<Td colSpan={6}>
										<Collapse in={openRows.includes(index)}>
											<Box p={4}>
												{/* Content to display when the row is expanded */}
												<IndividualTrainingInfo
													trainingId={row.original._id}
													title={row.original.title}
												/>
											</Box>
										</Collapse>
									</Td>
								</Tr>
							</React.Fragment>
						);
					})}
					{/* <AccordionIcon />
							</AccordionButton>
							<AccordionPanel>Lets see if this will work</AccordionPanel>
						</AccordionItem>
					</Accordion> */}
				</Tbody>
			</Table>

			<Flex m={4} justifyContent={['flex-start', 'flex-start', 'center']}>
				<Flex>
					<Tooltip label="First Page">
						<IconButton
							onClick={() => gotoPage(0)}
							isDisabled={!canPreviousPage}
							icon={<ArrowLeftIcon h={3} w={3} />}
							mr={4}
							aria-label=""
						/>
					</Tooltip>
					<Tooltip label="Previous Page">
						<IconButton
							onClick={previousPage}
							isDisabled={!canPreviousPage}
							icon={<ChevronLeftIcon h={6} w={6} />}
							aria-label=""
						/>
					</Tooltip>
				</Flex>

				<Flex alignItems="center">
					<Text fontSize={['xs', 'md']} flexShrink="0" mr={8}>
						Page{' '}
						<Text fontWeight="bold" as="span">
							{pageIndex + 1}
						</Text>{' '}
						of{' '}
						<Text fontWeight="bold" as="span">
							{pageOptions.length}
						</Text>
					</Text>
					<Text fontSize={['xs', 'md']} flexShrink="0">
						Go to page:
					</Text>{' '}
					<NumberInput
						ml={2}
						mr={8}
						w={28}
						min={1}
						max={pageOptions.length}
						onChange={(value) => {
							// @ts-ignore
							const pageToGoto = value ? value - 1 : 0;
							gotoPage(pageToGoto);
						}}
						defaultValue={pageIndex + 1}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<Select
						w={32}
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
					>
						{[10, 20, 30, 40, 50].map((pageSizeAmount) => (
							<option key={pageSizeAmount} value={pageSizeAmount}>
								Show {pageSizeAmount}
							</option>
						))}
					</Select>
				</Flex>

				<Flex>
					<Tooltip label="Next Page">
						<IconButton
							onClick={nextPage}
							isDisabled={!canNextPage}
							icon={<ChevronRightIcon h={6} w={6} />}
							aria-label=""
						/>
					</Tooltip>
					<Tooltip label="Last Page">
						<IconButton
							onClick={() => gotoPage(pageCount - 1)}
							isDisabled={!canNextPage}
							icon={<ArrowRightIcon h={3} w={3} />}
							ml={4}
							aria-label=""
						/>
					</Tooltip>
				</Flex>
			</Flex>
		</>
	);
}

function AllTrainingsTable({
	trainings,
	setTrainings,
}: AllTrainingsTableProps) {
	const columns = React.useMemo(() => {
		return [
			{
				Header: ' ',
				columns: [
					{
						Header: 'Date',
						accessor: (row: any) => {
							return moment(row.date).local().format('DD-MM-YYYY');
						},
					},
					{
						Header: 'Title',
						accessor: 'title',
					},
					{
						Header: 'Organization',
						accessor: 'organization',
					},
					{
						Header: 'State',
						accessor: 'state',
					},
					{
						Header: 'Approach',
						accessor: 'approach',
					},
				],
			},
		];
	}, [trainings, setTrainings]);

	return <CustomTable columns={columns} data={trainings} />;
}

export default AllTrainingsTable;
