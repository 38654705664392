/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { EditIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Hide,
	Input,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import useSWR from 'swr';
import instance from '../api/api';
import IModule from '../interfaces/IModule';
import LoadingSpinner from './LoadingSpinner';

interface EditUserDrawerProps {
	userId: string;
	userType: string;
	userList: any;
}

export default function EditUserDrawer({
	userType,
	userId,
	userList,
}: EditUserDrawerProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [userUpdate, setUserUpdate] = useState({});
	const [currentUserDetails, setCurrentUserDetails] = useState<any>();
	const [modules, setModules] = useState<IModule[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const firstField = useRef();
	const toast = useToast();

	const statuses = [
		{ id: 1, status: 'Pending' },
		{ id: 2, status: 'Enrolled' },
		{ id: 3, status: 'Completed' },
		{ id: 4, status: 'Suspended' },
	];

	const getModules = async (url: string) => {
		const response = await instance.get(url);
		if (response.data) setModules(response.data.modules);
		return response.data;
	};

	const { data: modulesData, error: modulesError } = useSWR(
		'/api/v1/modules',
		getModules
	);

	const getModuleName = (moduleID: any) => {
		console.log(modules);
		let foundModuleName;
		for (const module of modules) {
			if (module._id == moduleID) {
				foundModuleName = module.name;
			}
		}
		return foundModuleName;
	};

	const updateUserDetails = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			if (userType === 'Admin') {
				await instance.put(`/admin/admins/${userId}`, userUpdate);
				setIsLoading(false);
				toast({
					title: `${userType} Profile Updated`,
					description: `${userType} Profile updated successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
				onClose();
			}
			if (userType === 'Student') {
				await instance.put(`/api/v1/students/${userId}`, userUpdate);
				setIsLoading(false);
				toast({
					title: `${userType} Profile Updated`,
					description: `${userType} Profile updated successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
				onClose();
			}
		} catch (error) {
			setIsLoading(false);
			toast({
				title: 'Profile Update Failed',
				description: 'Profile unsuccessful. Please try again.',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
			onClose();
		}
	};

	return (
		<>
			<Box
				as={MenuItem}
				icon={<EditIcon />}
				onClick={() => {
					const userDetails = userList.filter(
						(user: any) => user._id === userId
					);
					setCurrentUserDetails(userDetails[0]);
					onOpen();
				}}
			>
				<Hide below="sm">
					<Text>Edit User</Text>
				</Hide>
			</Box>
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				// @ts-ignore
				initialFocusRef={firstField}
				onClose={onClose}
			>
				<DrawerOverlay />
				<DrawerContent bg="#FEFAE0">
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Edit User</DrawerHeader>

					<DrawerBody>
						<form onSubmit={updateUserDetails}>
							<Stack spacing="24px">
								<Box>
									<FormLabel htmlFor="firstName">First Name</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setUserUpdate((prevState) => ({
												...prevState,
												firstName: e.target.value,
											}));
										}}
										defaultValue={currentUserDetails?.firstName}
										// @ts-ignore
										ref={firstField}
										id="firstname"
										placeholder="John"
									/>
								</Box>
								<Box>
									<FormLabel htmlFor="surname">Last Name</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setUserUpdate((prevState) => ({
												...prevState,
												surname: e.target.value,
											}));
										}}
										defaultValue={currentUserDetails?.surname}
										id="surname"
										placeholder="Smith"
									/>
								</Box>
								{!currentUserDetails?.isAdmin && (
									<Box>
										<FormLabel fontWeight={700} htmlFor="module">
											Change Status
										</FormLabel>
										<Select
											id="status"
											onChange={(e) => {
												setUserUpdate((prevState: any) => ({
													...prevState,
													status: e.target.value,
												}));
											}}
										>
											<option value="">Change Status</option>
											{statuses.map((option: any) => (
												<option
													key={option?.id}
													value={option.status}
													defaultValue={currentUserDetails?.status}
												>
													{option.status}
												</option>
											))}
										</Select>
									</Box>
								)}
								<Box>
									<FormLabel htmlFor="email">Email Address</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setUserUpdate((prevState) => ({
												...prevState,
												emailAddress: e.target.value,
											}));
										}}
										defaultValue={currentUserDetails?.emailAddress}
										type="email"
										id="email"
										placeholder="johnsmith@farmai.africa"
									/>
								</Box>
								{currentUserDetails?.isAdmin && (
									<Stack>
										<Text fontSize="md" fontWeight="medium">
											Is the user an Admin?
										</Text>
										<RadioGroup
											onChange={(value) => {
												setUserUpdate((prevState) => ({
													...prevState,
													/* eslint-disable */
                          isAdmin: value === "Yes" ? true : false,
                          /* eslint-enable */
												}));
											}}
											// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
											defaultValue={currentUserDetails?.isAdmin ? 'Yes' : 'No'}
										>
											<Stack spacing={5} direction="row">
												<Radio colorScheme="red" value="Yes">
													Yes
												</Radio>
												<Radio colorScheme="green" value="No">
													No
												</Radio>
											</Stack>
										</RadioGroup>
									</Stack>
								)}
							</Stack>
							<Flex
								mt="5"
								justifyContent="center"
								flexDir="column"
								as={DrawerFooter}
							>
								<Button
									w="100%"
									mb="2"
									borderColor="#00172D"
									_hover={{
										background: '#00172D',
										color: 'white',
									}}
									variant="outline"
									onClick={onClose}
								>
									Cancel
								</Button>

								<Button
									w="100%"
									isLoading={isLoading}
									isDisabled={Object.keys(userUpdate).length === 0}
									_hover={{
										border: '1px solid #00172D',
										bgColor: '#FFF',
										color: 'black',
									}}
									bgColor="#00172D"
									color="white"
									type="submit"
								>
									Update User
								</Button>
							</Flex>
						</form>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
