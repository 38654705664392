/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SyntheticEvent, useEffect, useState } from 'react';
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Link,
	Image,
	Hide,
	Stack,
	FormControl,
	FormLabel,
	useToast,
	InputGroup,
	Select,
	InputLeftElement,
} from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import validateEmail from '../constants/validateEmail';
import validatePhoneNumber from '../constants/validatePhoneNumber';
import { AiOutlineMail } from 'react-icons/ai';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import instance from '../api/api';
import validateNames from '../constants/validate';
import { IUser } from '../interfaces/IUser';

const validate = validateNames();

export default function Register() {
	const [passwordShown, setPasswordShown] = useState(false);
	const navigate = useNavigate();
	const toast = useToast();

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<IUser>();

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const [formErrors, setFormErrors] = useState({
		firstname: '',
		lastname: '',
		emailAddress: '',
		password: '',
	});

	const onSubmit = async (data: IUser) => {
		try {
			const response = await instance.post('/api/v1/users/register', data);
			if (response) {
				console.log(response, data);
				navigate('/login');
			}
			if (!response) {
				toast({
					title: 'Account not created.',
					description:
						'Sorry your registration was not successful. Please try again later.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
			return response.data;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				toast({
					title: 'Account not created.',
					description:
						'Sorry your registration was not successful. Please try again later.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
				return error.message;
			}
			return 'An unexpected error occurred';
		}
	};

	const uploadError = {
		firstName: {
			required: 'First name is required',
			minLength: 3,
			pattern: {
				value: validate,
				message: 'Invalid character!!',
			},
		},
		surname: {
			required: 'Last name is required',
			minLength: 3,
			pattern: {
				value: validate,
				message: 'Invalid character!!',
			},
		},
		emailAddress: {
			required: 'Email address is required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email address',
			},
		},
		password: {
			required: 'Please enter a password',
			minLength: 6,
		},
	};

	return (
		<div>
			<Navbar />
			<Stack h="100vh" align={'center'}>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Stack>
						<Flex
							direction="column"
							justifyContent="center"
							alignSelf="center"
							mt="1em"
							w={[300, 400, 500]}
						>
							<Box w={[300, 400, 500]} textAlign="center" height="100px">
								<Text
									color="black"
									fontSize={{ base: '3xl', md: '5xl' }}
									fontWeight={400}
								>
									Register
								</Text>
								<Hide>
									<Text>Let&apos;s log you into your account</Text>
								</Hide>
							</Box>
							<FormControl isRequired>
								<FormLabel fontWeight={600} className="label">
									First Name
								</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents="none">
										<AiOutlineMail />
									</InputLeftElement>
									<Input
										borderColor="#D9D9D9"
										placeholder="First Name"
										required
										{...register('firstName', uploadError.firstName)}
										mb="10px"
										fontWeight={200}
									/>
								</InputGroup>
								{errors?.firstName && (
									<p className="error">{errors.firstName.message}</p>
								)}
								{errors?.firstName?.type === 'minLength' && (
									<p className="error">
										First name must be at least 3 characters long
									</p>
								)}
							</FormControl>
							<FormControl isRequired>
								<FormLabel fontWeight={600} className="label">
									Surname
								</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents="none">
										<AiOutlineMail />
									</InputLeftElement>
									<Input
										borderColor="#D9D9D9"
										placeholder="Surname"
										required
										{...register('surname', uploadError.surname)}
										mb="10px"
										fontWeight={200}
									/>
								</InputGroup>
								{errors?.surname && (
									<p className="error">{errors.surname.message}</p>
								)}
								{errors?.surname?.type === 'minLength' && (
									<p className="error">
										Last name must be at least 3 characters long
									</p>
								)}
							</FormControl>
							<FormControl isRequired>
								<FormLabel fontWeight={600} className="label">
									Email address
								</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents="none">
										<AiOutlineMail />
									</InputLeftElement>
									<Input
										borderColor="#D9D9D9"
										placeholder="Email Address"
										required
										{...register('emailAddress', uploadError.emailAddress)}
										mb="10px"
										fontWeight={200}
									/>
								</InputGroup>
								{errors?.emailAddress && (
									<p className="error">{errors.emailAddress.message}</p>
								)}
							</FormControl>
							<FormControl isRequired>
								<FormLabel fontWeight={600} className="label">
									Password
								</FormLabel>
								<InputGroup>
									<InputLeftElement>
										{!passwordShown ? (
											<BsFillEyeFill onClick={togglePassword} />
										) : (
											<BsFillEyeSlashFill onClick={togglePassword} />
										)}
									</InputLeftElement>
									<Input
										type={passwordShown ? 'text' : 'password'}
										borderColor="#D9D9D9"
										role="textbox"
										placeholder="Password"
										required
										{...register('password', uploadError.password)}
										mb="10px"
										fontWeight={200}
									/>
								</InputGroup>
								{errors?.password && (
									<p className="error">{errors.password.message}</p>
								)}
								{errors?.password?.type === 'minLength' && (
									<p className="error">
										Password must be at least 6 characters long
									</p>
								)}
								{/* {apiResponse !== null && (
									<span className="response">{apiResponse}</span>
								)} */}
							</FormControl>
							<Button
								type="submit"
								alignContent="left"
								fontSize="xl"
								color="white"
								isLoading={isSubmitting}
								bgColor={'#00172D'}
								_hover={{
									border: '2px solid #00172D',
									color: 'black',
									bgColor: 'white',
								}}
							>
								Register
							</Button>
							<Flex
								direction="row"
								justifyContent={'space-around'}
								alignItems="center"
								mt={5}
								mb={7}
							>
								<Text p={1}>
									Already registered?{' '}
									<Link
										aria-label="sign up"
										color="#00172D"
										fontWeight={500}
										textDecoration="underline"
										href="/login"
									>
										{' '}
										Login
									</Link>
								</Text>
							</Flex>
						</Flex>
					</Stack>
				</form>
			</Stack>
		</div>
	);
}
