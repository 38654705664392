/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, { AxiosResponse } from 'axios';
import { Logout } from "../context/Auth/AuthContext";
import { useState } from 'react';
import IModule from '../interfaces/IModule';
import useSWR from "swr";

const token = localStorage.getItem('token');

const instance = axios.create({
//   baseURL: 'http://localhost:8000', // local development
  baseURL: 'https://sctraining-server.onrender.com',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const interceptorId = instance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			Logout();
		}
	}
);

export function RetrieveModules() {
	const [modules, setModules] = useState<IModule[]>([]);

	const getModules = async (url: string) => {
		const response = await instance.get(url);
		if (response.data) setModules(response.data.modules);
	};

	useSWR('/api/v1/modules/', getModules);

	return modules;
}

export default instance;
