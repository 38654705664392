/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ArrowUpIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import instance from '../api/api';

export default function ImportStudentsModal() {
	const [data, setData] = useState<any>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const toast = useToast();
	const convertData = (imported_data: any[] | null) => {
		const convertedData: any[] = [];
		imported_data?.forEach((entry) => {
			const convertedEntry = {
				firstName: entry['First Name'],
				surname: entry['Last Name'],
				gender: entry.Sex,
				module: entry.Cadre,
				profession: entry.Position,
				facility: entry['Facility/Organization'],
				phoneNumber: entry['Phone Number'],
				emailAddress: entry['Email Address'],
			};
			convertedData.push(convertedEntry);
		});
		return convertedData;
	};
	const handleFileChange = (e: any) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			const binaryData = e.target?.result;
			const workbook = read(binaryData, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData: any = utils.sheet_to_json(sheet);

			setData(jsonData);
			// convertData(jsonData);
		};
		if (file) reader.readAsBinaryString(file);
	};
	convertData(data);

	const uploadData = async () => {
		setIsSubmitting(true);

		try {
			for (const d of convertData(data)) {
				await instance.post('/api/v1/students/add-student', d);
			}
			toast({
				title: 'Students uploaded successfully',
				description: 'All students uploaded',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
			onClose();
			return;
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Failed to add students',
				status: 'error',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
		}

		setData([]);
	};
	return (
		<>
			<Button
				bgColor="#00172D"
				color="white"
				border="1px solid #00172D"
				borderRadius="6px"
				textColor="white"
				_hover={{
					textColor: 'black',
					bgColor: 'white',
					border: '1px solid #00172D',
				}}
				leftIcon={<ArrowUpIcon color={'white'} />}
				onClick={onOpen}
			>
				Import Students
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Import Data</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex>
							<input type="file" accept=".xlsx" onChange={handleFileChange} />
							{data && <Text fontWeight={700}>{data.length} Entries</Text>}
						</Flex>
						{data && (
							<TableContainer>
								<Table variant={'simple'}>
									<Thead>
										<Tr>
											<Th>First Name</Th>
											<Th>Last Name</Th>
											<Th>Sex</Th>
											<Th>Cadre</Th>
											<Th>Position</Th>
											<Th>Facility/Organization</Th>
											<Th>Phone Number</Th>
											<Th>Email Address</Th>
										</Tr>
									</Thead>
									<Tbody>
										{data.map((d: any, index: number) => (
											<Tr key={index}>
												<Td>{d['First Name']}</Td>
												<Td>{d['Last Name']}</Td>
												<Td>{d.Sex}</Td>
												<Td>{d.Cadre}</Td>
												<Td>{d.Position}</Td>
												<Td>{d['Facility/Organization']}</Td>
												<Td>{d['Phone Number']}</Td>
												<Td>{d['Email Address']}</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</TableContainer>
						)}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
						<Button
							onClick={uploadData}
							isLoading={isSubmitting}
							bgColor="#00172D"
							color="white"
							border="1px solid #00172D"
							borderRadius="6px"
							textColor="white"
							_hover={{
								textColor: 'black',
								bgColor: 'white',
								border: '1px solid #00172D',
							}}
						>
							Upload
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
