/* eslint-disable react/jsx-no-undef */
import {
	Box,
	Flex,
	Hide,
	Tab,
	Text,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import Header from '../components/AdminHeader';
import SearchButton from '../components/SearchButton';
import StudentRequests from '../components/StudentRequests';

export default function AdminApplications() {
	return (
		<Box>
			<Header />
			<Box className="title" pt="34">
				<Flex
					pt="20px"
					pl={[10, 10, 75]}
					direction={{ base: 'column', md: 'row' }}
					alignItems={['left', 'left', 'center']}
				>
					<Text
						color="#303133"
						fontWeight="bold"
						fontSize={['25', '38']}
						pb="15px"
						pr={55}
						mr={10}
					>
						Applications
					</Text>
					<Hide>
						<SearchButton placeholder="Search for users" />
					</Hide>
				</Flex>
				<Tabs isLazy>
					<TabList pl={[12, 12, 70]}>
						<Tab color="#303133">Students</Tab>
					</TabList>
					<TabPanels pl={['10px', '5px', '70px']} pr={['10px', '5px', '70px']}>
						<TabPanel>
							<StudentRequests />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
}
