/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	Flex,
	Hide,
	Text,
	Box,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BiBody } from 'react-icons/bi';
import { FaUserCog } from 'react-icons/fa';
import Header from '../components/AdminHeader';
import useSWR from 'swr';
import { IAdmin } from '../interfaces/IAdmin';
import { IStudent } from '../interfaces/IStudent';
import instance from '../api/api';
import STATUS from '../constants/status';
import UserSummaryCard from '../components/UserSummaryCard';
import AllAdminsTable from '../components/AllAdminsTables';
import AllStudentsTable from '../components/AllStudentsTable';
import AddNewAdminDrawer from '../components/AddNewAdminDrawer';
import IModule from '../interfaces/IModule';
import ImportFacilitatorsModal from '../components/ImportFacilitatorsModal';
import ImportStudentsModal from '../components/ImportStudentsModal';
import AllFacilitatorsTable from '../components/AllFacilitatorsTable';
import { IFacilitator } from '../interfaces/IFacilitator';

export default function AdminAllUsers() {
	const [enrolledStudents, setEnrolledStudents] = useState(0);
	const [userAdmins, setUserAdmins] = useState<IAdmin[]>([]);
	const [userStudents, setUserStudents] = useState<IStudent[]>([]);
	const [facilitators, setFacilitators] = useState<IFacilitator[]>([]);
	const toast = useToast();

	const getAdmins = async (url: string) => {
		try {
			const response = await instance.get(url);
			setUserAdmins(response.data.admins);
		} catch (error) {
			toast({
				title: 'Failed to load admins',
				description: 'Fetching admins failed. Please reload the tab',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	};

	const getStudents = async (url: string) => {
		try {
			const response = await instance.get(url);
			setUserStudents(response.data.students);

			const enrolledUserStudents = response.data.students.filter(
				(student: IStudent) => student.status === STATUS.ENROLLED
			);

			setEnrolledStudents(enrolledUserStudents.length);
		} catch (error) {
			toast({
				title: 'Failed to load students',
				description: 'Fetching students failed. Please reload the tab',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	};

	const getFacilitators = async (url: string) => {
		try {
			const response = await instance.get(url);
			setFacilitators(response.data.facilitators);
		} catch (error) {
			toast({
				title: 'Failed to load facilitators',
				description: 'Fetching facilitators failed. Please reload the tab',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	};

	useSWR('/admin/admins', getAdmins);
	useSWR('/api/v1/students', getStudents);
	useSWR('/api/v1/facilitators', getFacilitators);

	return (
		<Box>
			<Header />
			<Box className="title" pt="34">
				<Flex
					pt="20px"
					pl={[5, 10, 59.9]}
					direction={{ base: 'column', md: 'row' }}
					mb="5"
				>
					<Text
						color="#303133"
						fontWeight="bold"
						fontSize="3xl"
						pb="15px"
						pl={[0, 0, 5]}
						w={['100%', '100%', '30%']}
					>
						Registered Users
					</Text>

					<Flex
						w="100%"
						justifyContent={['flex-start', 'flex-start', 'flex-end']}
						alignItems={['flex-start', 'center']}
						flexDir={['column', 'row']}
						pr="55"
						gap={10}
					>
						<AddNewAdminDrawer />
						<ImportFacilitatorsModal />
						<ImportStudentsModal />
					</Flex>
				</Flex>
				<Flex
					p="12"
					flexDir={['column', 'row']}
					mb={['2', '2', '3']}
					gap="10px"
				>
					<UserSummaryCard
						title="Enrolled Students"
						icon={BiBody}
						activeUsersCount={enrolledStudents}
					/>
					<UserSummaryCard
						title="Admins"
						icon={FaUserCog}
						activeUsersCount={userAdmins.length}
					/>
				</Flex>
				<Tabs>
					<TabList ml={[0, 5, 70]}>
						<Tab>Admins</Tab>
						<Tab>Students</Tab>
						<Tab>Facilitators</Tab>
					</TabList>
					<TabPanels pl={['2', '2', '10']} pr="10" overflow="auto">
						<TabPanel>
							<AllAdminsTable
								admins={userAdmins}
								setUserAdmins={setUserAdmins}
							/>
						</TabPanel>
						<TabPanel>
							<AllStudentsTable
								students={userStudents}
								setUserStudents={setUserStudents}
							/>
						</TabPanel>
						<TabPanel>
							<AllFacilitatorsTable
								facilitators={facilitators}
								setFacilitators={setFacilitators}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
}
