import {
	Stack,
	Flex,
	Heading,
	Box,
	Text,
	Container,
	Icon,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { BsTable } from 'react-icons/bs';
import { GiTeamIdea } from 'react-icons/gi';
import { VscGraph, VscPerson } from 'react-icons/vsc';
import { IoMdTimer } from 'react-icons/io';
import { ImStack } from 'react-icons/im';

interface CardProps {
	heading: string;
	description: string;
	icon: ReactElement;
	href?: string;
}

const Card = ({ heading, description, icon, href }: CardProps) => {
	return (
		<Box
			maxW={{ base: 'full', md: '30%' }}
			w={'full'}
			borderWidth="1px"
			borderRadius="lg"
			overflow="hidden"
			p={5}
		>
			<Stack align={'center'} spacing={2}>
				<Flex
					w={16}
					h={16}
					align={'center'}
					justify={'center'}
					color={'white'}
					rounded={'full'}
				>
					{icon}
				</Flex>
				<Box mt={2} textAlign={'center'}>
					<Heading size="md">{heading}</Heading>
					<Text mt={1} fontSize={'sm'}>
						{description}
					</Text>
				</Box>
			</Stack>
		</Box>
	);
};

export default function Offerings() {
	return (
		<Stack>
			<Stack
				p={10}
				justifyContent={{ base: 'center', lg: 'left' }}
				w={{ base: '100%', lg: '50%' }}
			>
				<Heading fontSize={'3xl'} lineHeight={'1.7em'}>
					We offer the best quality short course tailored to meet your
					professional development needs.
				</Heading>
				<Text lineHeight={'2em'} fontSize={'xl'}>
					Our team of experts has years of experience in designing and
					delivering training programs that are effective, engaging, and
					relevant to your specific requirements. <br /> Our approach is based
					on the latest research and best practices in adult learning and
					development. We use a variety of instructional methods and
					technologies to ensure that our training programs are interactive,
					engaging, and effective. We also incorporate real-world clinic based
					case studies to make the courses relevant and practical. <br />{' '}
					Whether you are looking to unskilled or reskill, we have got you
					covered with our contextualized and practical short course program.
					Join us and elevate your expertise to the next level
				</Text>
			</Stack>
			<Container
				maxW={'10xl'}
				mt={12}
				py={10}
				px={12}
				justifyContent={'center'}
				justifyItems={'center'}
			>
				<Flex flexWrap="wrap" gridGap={12} justify="center">
					<Card
						heading={'Target'}
						icon={<Icon as={VscGraph} w={10} h={10} color={'blue'} />}
						description={
							'Tailored to the target audience of clinical officers, nurse/midwives, and laboratory officers.'
						}
						href={'#'}
					/>
					<Card
						heading={'Quality'}
						icon={<Icon as={ImStack} w={10} h={10} color={'green'} />}
						description={
							'Program content designed to meet the highest quality standards and continuously updated to reflect the latest developments in healthcare.'
						}
						href={'#'}
					/>
					<Card
						heading={'Experts'}
						icon={<Icon as={VscPerson} w={10} h={10} color={'orange'} />}
						description={
							'Facilitated by subject matter experts with extensive experience in their respective fields.'
						}
						href={'#'}
					/>
					<Card
						heading={'Virtual'}
						icon={<Icon as={IoMdTimer} w={10} h={10} color={'yellow'} />}
						description={'Virtual sessions provided at no cost to candidates'}
						href={'#'}
					/>
					<Card
						heading={'Mentorship'}
						icon={<Icon as={GiTeamIdea} w={10} h={10} color={'red'} />}
						description={
							'Routine on-site mentorship to support the application of knowledge and skills in practice.'
						}
						href={'#'}
					/>
					<Card
						heading={'Record'}
						icon={<Icon as={BsTable} w={10} h={10} color={'blue'} />}
						description={
							'Track record of successfully delivering similar programs and ensuring a positive overall program experience for participants.'
						}
						href={'#'}
					/>
				</Flex>
			</Container>
		</Stack>
	);
}
