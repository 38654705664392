/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AddIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	FormLabel,
	Input,
	Stack,
	useDisclosure,
	Text,
	Hide,
	Radio,
	RadioGroup,
	DrawerFooter,
	Flex,
	useToast,
	Toast,
	Select,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import useSWR from 'swr';
import instance from '../api/api';
import { ICourse } from '../interfaces/ICourse';
import IModule from '../interfaces/IModule';
import LoadingSpinner from './LoadingSpinner';

export default function AddNewCourseDrawer() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newCourse, setNewCourse] = useState<ICourse>();
	const [objectives, setObjectives] = useState<string[]>([]);
	const [competencies, setCompetencies] = useState<string[]>([]);
	const [presenters, setPresenters] = useState<string[]>([]);
	const toast = useToast();
	const [modules, setModules] = useState<IModule[]>([]);

	const getModules = async (url: string) => {
		const response = await instance.get(url);
		if (response.data) setModules(response.data.modules);
		return response.data;
	};

	const { data: modulesData, error: modulesError } = useSWR(
		'/api/v1/modules/',
		getModules
	);

	if (!modulesData || modulesError) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	const handleAddObjectives = () => {
		setObjectives([...objectives, '']);
	};

	const handleChangeObjectives = (index: any, event: any) => {
		const updatedObjectives = [...objectives];
		updatedObjectives[index] = event.target.value;
		setObjectives(updatedObjectives);
	};

	const handleAddCompetencies = () => {
		setCompetencies([...competencies, '']);
	};

	const handleChangeCompetencies = (index: any, event: any) => {
		const updatedCompetencies = [...competencies];
		updatedCompetencies[index] = event.target.value;
		setCompetencies(updatedCompetencies);
	};

	const handleAddPresenters = () => {
		setPresenters([...presenters, '']);
	};

	const handleChangePresenters = (index: any, event: any) => {
		const updatedPresenters = [...presenters];
		updatedPresenters[index] = event.target.value;
		setPresenters(updatedPresenters);
	};

	const clearAllArrayValues = () => {
		setObjectives([]);
		setCompetencies([]);
		setPresenters([]);
	};

	const createNewCourse = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			await instance.post(
				`/api/v1/courses/modules/${newCourse?.module}/add-course`,
				newCourse
			);

			toast({
				title: 'Course Added',
				description: 'New course successfully added',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
			clearAllArrayValues();
			onClose();
			return;
		} catch (error) {
			toast({
				title: 'Course creating failed',
				description: 'Failed to add new course. Please try again...',
				status: 'error',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Button
				bgColor="#00172D"
				color="white"
				border="1px solid #00172D"
				borderRadius="6px"
				textColor="white"
				_hover={{
					textColor: 'black',
					bgColor: 'white',
					border: '1px solid #00172D',
				}}
				leftIcon={<AddIcon color={'white'} />}
				onClick={onOpen}
			>
				Add Course
				{/* <Hide below="sm">
					<Text color={'white'}>Add Course</Text>
				</Hide> */}
			</Button>
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					onClose();
					clearAllArrayValues();
				}}
			>
				<DrawerContent bg="#FEFAE0">
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Add New Course</DrawerHeader>

					<DrawerBody>
						<form onSubmit={createNewCourse}>
							<Stack spacing="24px">
								<Box>
									<FormLabel fontWeight={700} htmlFor="module">
										Module
									</FormLabel>
									<Select
										id="module"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												module: e.target.value,
											}));
										}}
									>
										<option value="">Select Module</option>
										{modules.map((option: any) => (
											<option key={option?._id} value={option._id}>
												{option.name}
											</option>
										))}
									</Select>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="topic">
										Topic
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												topic: e.target.value,
											}));
										}}
										id="topic"
										placeholder="Topic"
										required
									/>
								</Box>
								{/* Objectives */}
								<Stack>
									<FormLabel fontWeight={700} htmlFor="objectives">
										Objectives
									</FormLabel>
									{objectives.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangeObjectives(index, e);
												setNewCourse((prevState: any) => ({
													...prevState,
													objectives,
												}));
											}}
											id="objective"
											placeholder="Add Objective"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddObjectives}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>
								<Stack>
									<FormLabel fontWeight={700} htmlFor="competencies">
										Competencies
									</FormLabel>
									{competencies.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangeCompetencies(index, e);
												setNewCourse((prevState: any) => ({
													...prevState,
													competencies,
												}));
											}}
											id="competency"
											placeholder="Add Competency"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddCompetencies}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>

								<Stack>
									<FormLabel fontWeight={700} htmlFor="presenters">
										Presenters
									</FormLabel>
									{presenters.map((value, index) => (
										<Input
											key={index}
											value={value}
											borderColor="#A0AEC0"
											onChange={(e) => {
												handleChangePresenters(index, e);
												setNewCourse((prevState: any) => ({
													...prevState,
													presenters,
												}));
											}}
											id="presenter"
											placeholder="Add Presenters"
											required
										/>
									))}
									<Button
										w="10%"
										bgColor="#00172D"
										color="white"
										border="1px solid #00172D"
										borderRadius="6px"
										textColor="white"
										onClick={handleAddPresenters}
										_hover={{
											textColor: 'black',
											bgColor: 'white',
											border: '1px solid #00172D',
										}}
										iconSpacing={0}
										rightIcon={
											<AddIcon color={'white'} _hover={{ color: 'black' }} />
										}
									></Button>
								</Stack>
								<Box>
									<FormLabel fontWeight={700} htmlFor="date">
										Date
									</FormLabel>
									<Input
										type="date"
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												date: e.target.value,
											}));
										}}
										id="date"
										placeholder="Date"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="time">
										Duration (in minutes)
									</FormLabel>
									<Input
										type="number"
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												time: e.target.value,
											}));
										}}
										id="time"
										placeholder="Duration"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="meetingLink">
										Meeting Link
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												meetingLink: e.target.value,
											}));
										}}
										id="meetingLink"
										placeholder="Add Meeting Link"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="meetingID">
										Meeting ID
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												meetingID: e.target.value,
											}));
										}}
										id="meetingID"
										placeholder="Add Meeting ID"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="password">
										Meeting Password
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												password: e.target.value,
											}));
										}}
										id="password"
										placeholder="Add Meeting Password"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="sessionEvaluationLink">
										Session Evaluation Link
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												sessionEvaluationLink: e.target.value,
											}));
										}}
										id="sessionEvaluationLink"
										placeholder="Session Evaluation Link"
										required
									/>
								</Box>
								<Box>
									<FormLabel fontWeight={700} htmlFor="formativeAssessmentLink">
										Formative Assessment Link
									</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewCourse((prevState: any) => ({
												...prevState,
												formativeAssessmentLink: e.target.value,
											}));
										}}
										id="formativeAssessmentLink"
										placeholder="Formative Assessment Link"
									/>
								</Box>
							</Stack>
							<Flex
								mt="5"
								justifyContent="center"
								flexDir="column"
								as={DrawerFooter}
							>
								<Button
									w="100%"
									mb="2"
									borderColor="#00172D"
									_hover={{
										background: '#00172D',
										color: 'white',
									}}
									variant="outline"
									onClick={() => {
										onClose();
										clearAllArrayValues();
									}}
								>
									Cancel
								</Button>
								<Button
									isLoading={isSubmitting}
									color="white"
									w="100%"
									_hover={{
										border: '1px solid #00172D',
										bgColor: '#FFF',
										color: 'black',
									}}
									bgColor="#00172D"
									type="submit"
								>
									Add Course
								</Button>
							</Flex>
						</form>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
