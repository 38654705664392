/* eslint-disable prettier/prettier */
import ROLES from './roles';

export const isLoggedIn = localStorage.getItem('token') !== null;
export const isLoggedInStudent =
  localStorage.getItem('token') !== null &&
  localStorage.getItem('role') === ROLES.STUDENT;
export const isLoggedInAdmin =
  localStorage.getItem('token') !== null &&
  localStorage.getItem('role') === ROLES.ADMIN;
export const isLoggedInUser = 
  localStorage.getItem('token')!== null &&
  localStorage.getItem('role') === ROLES.USER;

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getRole = () => {
  return localStorage.getItem('role');
};
export const getUserId = () => {
  const userId = localStorage.getItem("userId");
  if (userId) return userId;
  return null
};

export const isAuthenticated =
  localStorage.getItem('token') !== null &&
  localStorage.getItem('role') !== null;
