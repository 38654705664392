import { createContext } from 'react';

export type IState = {
	token: string | null;
	userId: string | null;
	role: string | null;
};
interface AuthContextType {
	user: IState | null;
	setUser: React.Dispatch<React.SetStateAction<IState | null>>;
}

const userId = localStorage.getItem('userId');
const token = localStorage.getItem('token');
const role = localStorage.getItem('role');

export const initialState: IState = {
	token,
	userId,
	role,
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const Logout = () => {
	localStorage.clear();
};

export default AuthContext;
