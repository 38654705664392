/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Apply from './pages/Apply';
import Success from './components/Success';
import StudentPortal from './pages/StudentPortal';
import ProtectedRoutes from './components/ProtectedRoutes';
import AuthContext, { IState } from './context/Auth/AuthContext';
import CourseDescription from './pages/CourseDescription';
import StudentProfile from './pages/StudentProfile';
import AdminApplications from './pages/AdminApplications';
import AdminAllCourses from './pages/AdminAllCourses';
import AdminAllStudents from './pages/AdminAllUsers';
import AdminDashboard from './pages/AdminDashboard';
import Register from './pages/Register';
import Ineligible from './components/Ineligible';
import AdminDatabase from './pages/AdminDatabase';

function MainRoutes() {
	const [user, setUser] = useState<IState | null>(null);
	const userAuthValue = useMemo(() => ({ user, setUser }), [user, setUser]);
	return (
		<div>
			<AuthContext.Provider value={userAuthValue}>
				<ProtectedRoutes.StudentRoutes
					route={<StudentPortal />}
					path="student-dashboard"
				/>
				<ProtectedRoutes.StudentRoutes
					route={<CourseDescription />}
					path="course-description"
				/>
				<ProtectedRoutes.StudentRoutes
					route={<StudentProfile />}
					path="student-profile"
				/>
				<ProtectedRoutes.AdminRoutes
					route={<AdminDashboard />}
					path="dashboard"
				/>
				<ProtectedRoutes.AdminRoutes
					route={<AdminDatabase />}
					path="database"
				/>
				<ProtectedRoutes.AdminRoutes
					route={<AdminApplications />}
					path="applications"
				/>
				<ProtectedRoutes.AdminRoutes
					route={<AdminAllCourses />}
					path="all-courses"
				/>
				<ProtectedRoutes.AdminRoutes
					route={<AdminAllStudents />}
					path="all-users"
				/>
				<ProtectedRoutes.UserRoutes route={<Apply />} path="apply" />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/register" element={<Register />} />
					<Route path="/login" element={<Login />} />
					<Route path="/success" element={<Success />} />
					<Route path="/ineligible" element={<Ineligible />} />
				</Routes>
			</AuthContext.Provider>
		</div>
	);
}

export default MainRoutes;
