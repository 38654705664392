/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AddIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	FormLabel,
	Input,
	Stack,
	useDisclosure,
	Text,
	Hide,
	Radio,
	RadioGroup,
	DrawerFooter,
	Flex,
	useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import instance from '../api/api';

export interface NewAdmin {
	firstname: string;
	surname: string;
	isAdmin: boolean;
	emailAddress: string;
	password: string;
}

export default function AddNewAdminDrawer() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [newAdmin, setNewAdmin] = useState({});
	const firstField = React.useRef();
	const toast = useToast();

	const createNewAdmin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			await instance.post('/admin/createAdmin', newAdmin);

			toast({
				title: 'Admin Added',
				description: 'New Admin Successfully Added.',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
			onClose();
			return;
		} catch (error) {
			toast({
				title: 'Admin Creation Failed',
				description: 'New Admin Creation failed. Please try again..',
				status: 'error',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Button
				bgColor="#00172D"
				color="white"
				border="1px solid #00172D"
				borderRadius="6px"
				textColor="white"
				_hover={{
					textColor: 'black',
					bgColor: 'white',
					border: '1px solid #00172D',
				}}
				leftIcon={<AddIcon color={'white'} />}
				onClick={onOpen}
			>
				Create Admin
			</Button>
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				// @ts-ignore
				initialFocusRef={firstField}
				onClose={onClose}
			>
				<DrawerOverlay />
				<DrawerContent bg="#FEFAE0">
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Add New Admin</DrawerHeader>

					<DrawerBody>
						<form onSubmit={createNewAdmin}>
							<Stack spacing="24px">
								<Box>
									<FormLabel htmlFor="firstName">First Name</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewAdmin((prevState) => ({
												...prevState,
												firstName: e.target.value,
											}));
										}}
										// @ts-ignore
										ref={firstField}
										id="firstname"
										placeholder="John"
										required
									/>
								</Box>
								<Box>
									<FormLabel htmlFor="surname">Last Name</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewAdmin((prevState) => ({
												...prevState,
												surname: e.target.value,
											}));
										}}
										id="surname"
										placeholder="Smith"
										required
									/>
								</Box>
								<Box>
									<FormLabel htmlFor="email">Email Address</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewAdmin((prevState) => ({
												...prevState,
												emailAddress: e.target.value,
											}));
										}}
										type="email"
										id="email"
										placeholder="johnsmith@farmai.africa"
										required
									/>
								</Box>
								<Box>
									<FormLabel htmlFor="password">Password</FormLabel>
									<Input
										borderColor="#A0AEC0"
										onChange={(e) => {
											setNewAdmin((prevState) => ({
												...prevState,
												password: e.target.value,
											}));
										}}
										type="text"
										id="password"
										placeholder=""
										required
									/>
								</Box>

								<Stack>
									<Text fontSize="md" fontWeight="medium">
										Is the user an Admin?
									</Text>
									<RadioGroup
										onChange={(value) => {
											setNewAdmin((prevState) => ({
												...prevState,
												/* eslint-disable */
                        isAdmin: value === "Yes" ? true : false,
                        /* eslint-enable */
											}));
										}}
									>
										<Stack spacing={5} direction="row">
											<Radio colorScheme="red" value="Yes">
												Yes
											</Radio>
											<Radio colorScheme="green" value="No">
												No
											</Radio>
										</Stack>
									</RadioGroup>
								</Stack>
							</Stack>
							<Flex
								mt="5"
								justifyContent="center"
								flexDir="column"
								as={DrawerFooter}
							>
								<Button
									w="100%"
									mb="2"
									borderColor="#00172D"
									_hover={{
										background: '#00172D',
										color: 'white',
									}}
									variant="outline"
									onClick={() => {
										onClose();
									}}
								>
									Cancel
								</Button>
								<Button
									isLoading={isSubmitting}
									color="white"
									w="100%"
									_hover={{
										border: '1px solid #00172D',
										bgColor: '#FFF',
										color: 'black',
									}}
									bgColor="#00172D"
									type="submit"
								>
									Add Admin
								</Button>
							</Flex>
						</form>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
