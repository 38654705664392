import React from 'react';
import { Box, Image, Link } from '@chakra-ui/react';
import logo from '../images/Logo.svg';

function Logo() {
	return (
		<div>
			<Box maxW="70px" maxH="70px">
				<Link href="/">
					<Image src={logo} />
				</Link>
			</Box>
		</div>
	);
}

export default Logo;
