/* eslint-disable @typescript-eslint/no-floating-promises */
import {
	Avatar,
	Image,
	Stack,
	Button,
	Text,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	AvatarGroup,
	Badge,
	Flex,
	ToastId,
} from '@chakra-ui/react';
import emailIcon from '../images/emailIcon.svg';
import telephoneIcon from '../images/telephoneIcon.svg';

export interface StudentRequestProps {
	studentRequestDetails: {
		_id: string;
		firstName: string;
		surname: string;
		emailAddress: string;
		module: string;
		status: string;
	};
	module: string;
	updateStudentToEnrolled: (userId: string) => Promise<ToastId>;
	onClose: () => void;
	isOpen: boolean;
	isLoading: boolean;
}

export default function StudentRequestDetailsModal({
	studentRequestDetails,
	module,
	isOpen,
	onClose,
	updateStudentToEnrolled,
	isLoading,
}: StudentRequestProps) {
	return (
		<Modal onClose={onClose} isOpen={isOpen} isCentered>
			<ModalOverlay />
			<ModalContent bg="#FEFAE0">
				<ModalHeader>
					<Stack direction="row">
						<Badge borderRadius="lg" variant="solid" bgColor="#00172D">
							Module
						</Badge>
						<Text fontSize="xl">{module}</Text>
					</Stack>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex flexDir="row">
						<Text mr="5" fontWeight="bold" fontSize="2xl">
							{studentRequestDetails.firstName} {studentRequestDetails.surname}
						</Text>
					</Flex>
					<Flex p="3" flexDirection="column" bg="white">
						<Flex p="2">
							<Image w="5" mr="2" src={emailIcon} />
							<Text w="40">Email Address</Text>
							<Text fontSize="sm">{studentRequestDetails.emailAddress}</Text>
						</Flex>
						{/* <Flex p="2">
							<Text w="40">Module</Text>
							<Text>{studentRequestDetails.module}</Text>
						</Flex> */}
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button
						bgColor="#00172D"
						color="white"
						w="100%"
						isLoading={isLoading}
						onClick={() => {
							updateStudentToEnrolled(studentRequestDetails._id);
						}}
						_hover={{
							bgColor: 'white',
							border: '2px solid #00172D',
							color: 'black',
						}}
					>
						Accept User Request
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
