/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import AOS from 'aos';
import MainRoutes from './routes';

export function App() {
	AOS.init();
	return (
		<div className="App">
			<MainRoutes />
		</div>
	);
}
