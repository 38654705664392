/* eslint-disable import/no-unresolved */
import './spinner.css';

export default function LoadingSpinner() {
	return (
		<div className="spinner-container">
			<div className="loading-spinner" />
		</div>
	);
}
