import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import { Box } from '@chakra-ui/react';
import About from '../components/About';
import CoursesListing from '../components/CoursesListing';
import FeaturesSection from '../components/FeaturesSection';
import WhyChooseUs from '../components/WhyChooseUs';
import Offerings from '../components/Offerings';

export default function Home() {
	return (
		<Box>
			<Navbar />
			<Hero />
			<About />
			<CoursesListing />
			<FeaturesSection />
			<WhyChooseUs />
			<Offerings />
		</Box>
	);
}
