import { Stack, Grid, Text, Center, Box, Divider } from '@chakra-ui/react';
import Course1 from '../images/LandingPage/Course1.svg';
import Course2 from '../images/LandingPage/Course2.svg';
import Course3 from '../images/LandingPage/Course3.svg';
import CourseCard from './CourseCard';

export default function CoursesListing() {
	return (
		<Stack align={{ base: 'center', lg: 'left' }}>
			<Box textAlign="center" fontSize="2.5em">
				<Text fontWeight={700}>Courses</Text>
				<Center>
					<Divider
						border="1px solid #000000"
						w="95vw"
						justifySelf="center"
						orientation="horizontal"
					/>
				</Center>
			</Box>
			<Grid
				justifyContent="center"
				textAlign="center"
				templateColumns={{
					sm: 'repeat(1, 1fr)',
					md: 'repeat(1, 1fr)',
					lg: 'repeat(2, 1fr)',
					xl: 'repeat(3, 1fr)',
				}}
				p={{
					base: 4,
					lg: 8,
				}}
				gap={8}
			>
				<CourseCard
					image={Course1}
					title="CLINICAL OFFICERS"
					brochureLink="/"
				/>
				<CourseCard
					image={Course2}
					title="LABORATORY TECHNICIANS"
					brochureLink="/"
				/>
				<CourseCard image={Course3} title="NURSES/MIDWIVES" brochureLink="/" />
			</Grid>
			{/* <Box
				textAlign="center"
				fontSize="1.25em"
				fontWeight={600}
				color="#49B97A"
			>
				<Link
					href="/menu"
					_hover={{
						textDecoration: 'none',
					}}
				>
					<Button
						bgColor="#008037"
						color="white"
						borderRadius="15px"
						boxShadow="15px 15px 15px rgba(0, 0, 0, 0.25)"
						_hover={{
							border: '2px solid black',
							bgColor: 'white',
							color: 'black',
						}}
						colorScheme="brand"
						textDecoration="none"
						data-testid="button"
						fontSize="lg"
						h={{ base: '40px', md: '50px' }}
						w={[170, 170, 280]}
						mt="1em"
						mr={5}
					>
						APPLY NOW
					</Button>
				</Link>
			</Box> */}
		</Stack>
	);
}
