import { Stack, Flex, Text, Box, Img, Hide } from '@chakra-ui/react';
import LandingImage from '../images/Landing-image.svg';

export default function Hero() {
	return (
		<Flex
			align={'center'}
			justifyContent={'space-between'}
			p={{ base: 0, lg: 10 }}
			bgColor={'#36096d'}
			backgroundImage={'linear-gradient(315deg, #36096d 30%, #37d5d6 74%)'}
		>
			<Stack w={{ base: '100%', lg: '100%' }} p={{ base: 10, lg: 70 }}>
				<Text
					color={'white'}
					fontSize={'4xl'}
					fontWeight={700}
					textAlign={{ base: 'center', lg: 'center' }}
					p={5}
				>
					We specialize in workforce development for human resource for health
					in South Sudan
				</Text>
			</Stack>
			<Hide>
				<Stack
					w="50%"
					display={{
						base: 'none',
						lg: 'inline',
					}}
				>
					<Box
						display={{
							base: 'none',
							lg: 'inline',
						}}
					>
						<Img src={LandingImage} borderRadius={20} />
					</Box>
				</Stack>
			</Hide>
		</Flex>
	);
}
