/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
	Box,
	TableContainer,
	Table,
	Thead,
	Button,
	Text,
	Th,
	Tbody,
	Td,
	Tr,
	Flex,
	Stack,
	Select,
	useToast,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	HStack,
} from '@chakra-ui/react';
import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { RiDeleteBinLine } from 'react-icons/ri';
import instance from '../api/api';
import { ICourse } from '../interfaces/ICourse';
import formatDate from '../utils/formatDate';
import EditCourseDrawer from './EditCourseDrawer';
import IModule from '../interfaces/IModule';
import AddGradeModal from './AddGradeModal';
import { IStudent } from '../interfaces/IStudent';

interface AllCoursesTableProps {
	module: string;
	courses: ICourse[];
	setAllCourses: Dispatch<SetStateAction<ICourse[]>>;
}

function CoursesTable({
	courses,
	setAllCourses,
	module,
}: AllCoursesTableProps) {
	const toast = useToast();
	const cancelRef = useRef<HTMLButtonElement>(null);
	const [openAlert, setOpenAlert] = useState(false);
	const closeAlert = () => setOpenAlert(false);

	const [allStudents, setAllStudents] = useState<IStudent[]>([]);

	const getAllModuleStudents = async (url: string) => {
		const response = await instance.get(url);

		if (response.data) {
			console.log(response.data.students);
			setAllStudents(response.data.students);
		}
		return response.data;
	};

	useSWR(`/api/v1/modules/${module}/students`, getAllModuleStudents);

	const deleteCourse = async (_id: string) => {
		try {
			const res = await instance.delete(`/api/v1/courses/${_id}`);
			setAllCourses(courses.filter((value) => value._id !== _id));
			toast({
				title: 'Course deleted',
				description: 'Course deleted successfully',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			return res;
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response) {
					toast({
						title: 'Course not deleted',
						description: 'Course deletion was unsuccessful',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			}
			return error;
		}
	};

	return (
		<Box>
			<Box>
				<Box mb={5}>
					<TableContainer
						p={4}
						mt="15px"
						mb={30}
						border="1px dashed black"
						borderRadius="10px"
					>
						<Table size="sm" fontWeight="medium" variant="striped">
							<Thead h="60px" borderBottom="2px solid #DDA15E">
								<Tr>
									<Th>#</Th>
									<Th>Topic</Th>
									<Th>Presenters</Th>
									<Th>Date</Th>
									<Th>Duration</Th>
								</Tr>
							</Thead>
							<Tbody h="55px">
								{courses.map((course: ICourse, index: number) => (
									<Tr key={course._id} borderRadius="6px">
										<Td>{index + 1}</Td>
										<Td>{course.topic}</Td>
										<Td>{course.presenters[0]}</Td>
										<Td>{formatDate(course.date)}</Td>
										<Td>{course.time} minutes</Td>
										{/* {course.status === 'Completed' ? (
											<Td color="white">
												<Box
													border="3px solid #606C38"
													bgColor="#606C38"
													borderRadius="6px"
													textAlign="center"
													fontWeight={500}
													w={{ base: 'full', lg: '80%', xl: '50%' }}
												>
													{course.status}
												</Box>
											</Td>
										) : (
											<Td color="white">
												<Box
													border="3px solid #303133"
													bgColor="#303133"
													borderRadius="6px"
													textAlign="center"
													fontWeight={500}
													w={{ base: 'full', lg: '80%', xl: '50%' }}
												>
													{course.status}
												</Box>
											</Td>
										)} */}
										<Td>
											<Flex direction="row">
												<AddGradeModal
													moduleId={course.module}
													courseId={course._id}
													topic={course.topic}
													allStudents={allStudents}
												/>
												<EditCourseDrawer course={course} />
												<Button
													name="delete"
													role="button"
													data-testid="deleteButton"
													aria-label="deleteButton"
													aria-labelledby="deleteButton"
													w="auto"
													h={[26, 26, 26]}
													ml={4}
													fontSize={14}
													borderRadius={6}
													_hover={{ bgColor: '#BC6C25', color: 'white' }}
													bgColor="white"
													border="2px solid #BC6C25"
													color="black"
													px={{ base: 4, md: 8 }}
													fontWeight={600}
													onClick={() => {
														setOpenAlert(true);
														localStorage.setItem('course', course._id);
													}}
												>
													<span>
														<RiDeleteBinLine />
													</span>{' '}
													Delete
												</Button>
												{openAlert && (
													<AlertDialog
														isOpen={openAlert}
														leastDestructiveRef={cancelRef}
														onClose={closeAlert}
													>
														<AlertDialogContent>
															<AlertDialogHeader
																fontSize="lg"
																fontWeight="bold"
															>
																Delete Order
															</AlertDialogHeader>

															<AlertDialogBody>
																Are you sure? You cant undo this action
																afterwards.
															</AlertDialogBody>

															<AlertDialogFooter>
																<Button ref={cancelRef} onClick={closeAlert}>
																	Cancel
																</Button>
																<Button
																	colorScheme="red"
																	onClick={() => {
																		const orderId: string =
																			localStorage.getItem('course')!;
																		deleteCourse(orderId);
																		setOpenAlert(false);
																	}}
																	ml={3}
																>
																	Delete
																</Button>
															</AlertDialogFooter>
														</AlertDialogContent>
													</AlertDialog>
												)}
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</Box>
	);
}

export default CoursesTable;
