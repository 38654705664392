/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useState } from 'react';
import useSWR from 'swr';
import instance from '../api/api';
import StudentRequest, { StudentDetails } from './StudentRequest';

export default function StudentRequests() {
	const [pageNumber, setPageNumber] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [studentsList, setStudentsList] = useState<any>([]);

	const sendRequest = async (url: string) => {
		const response = await instance.get(url);

		const newStudentRequest: StudentDetails[] = response.data.students;

		setStudentsList((previous: any) => {
			if (
				JSON.stringify(previous) === JSON.stringify(newStudentRequest) ||
				response.data.totalItems === previous.length
			) {
				return previous;
			}

			return previous.concat(response.data.students);
		});

		return response.data;
	};

	const {
		data: studentRequestsData,
		isLoading,
		error,
	} = useSWR(
		`/api/v1/students/applications?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		sendRequest,
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			shouldRetryOnError: false,
			dedupingInterval: 0,
			refreshInterval: 0,
			refreshWhenHidden: false,
			refreshWhenOffline: false,
			refreshIntervalInBackground: false,
			key: `data-${pageNumber}`,
		}
	);

	return (
		<Flex textAlign="center" flexDirection="column">
			<SimpleGrid spacing={4} columns={[1, 2, 4]}>
				{studentsList.length > 0 &&
					studentsList.map((student: any) => {
						return (
							<StudentRequest
								setStudentRequestList={setStudentsList}
								key={student._id}
								studentRequestDetails={student}
							/>
						);
					})}
			</SimpleGrid>
			<Flex justifyContent="center">
				{studentRequestsData &&
					studentsList.length > 0 &&
					studentsList.length < studentRequestsData.totalItems && (
						<Button
							onClick={() => {
								setPageSize((prevPageSize) => {
									if (studentsList.length < prevPageSize) {
										return prevPageSize;
									}
									return prevPageSize + 8;
								});
								setPageNumber((prevPageNum) => {
									if (studentsList.length < pageSize) {
										return studentsList.length;
									}
									return prevPageNum + 8;
								});
							}}
							mt="10"
							isLoading={isLoading}
							w={['100%', '30%', '10%']}
						>
							Load More
						</Button>
					)}
			</Flex>

			{!studentRequestsData && <Text mt="2">Loading new requests....</Text>}

			{studentRequestsData && studentRequestsData.totalItems === 0 && (
				<Text>No new student requests!</Text>
			)}
			{error && (
				<Text>Failed to load new student requests! Try again later</Text>
			)}
		</Flex>
	);
}
