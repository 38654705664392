/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	Flex,
	Hide,
	Text,
	Box,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
} from '@chakra-ui/react';
import AllCoursesSearchBar from '../components/AllCoursesSearchBar';
import Header from '../components/AdminHeader';
import AddNewCourseDrawer from '../components/AddNewCourseDrawer';
import { useState } from 'react';
import useSWR from 'swr';
import instance from '../api/api';
import IModule from '../interfaces/IModule';
import LoadingSpinner from '../components/LoadingSpinner';
import AllCoursesTable from '../components/CoursesTable';
import { ICourse } from '../interfaces/ICourse';

interface AllModuleCoursesProps {
	_id: string;
	name: string;
	courses: ICourse[];
}
export default function AdminAllCourses() {
	const [individualCourse, setIndividualCourse] = useState<ICourse[]>([]);
	const [allModuleCourses, setAllModuleCourses] = useState<
		AllModuleCoursesProps[]
	>([]);

	const getAllModuleCourses = async (url: string) => {
		const response = await instance.get(url);
		if (response.data) {
			setAllModuleCourses(response.data.moduleCourses);
		}
		return response.data;
	};
	const { data: allModulesCoursesData, error: allModulesCoursesError } = useSWR(
		'/api/v1/courses/course/module-courses',
		getAllModuleCourses
	);
	if (!allModulesCoursesData || allModulesCoursesError) {
		return (
			<div>
				<Header />
				<LoadingSpinner />
			</div>
		);
	}

	const renderTables = (
		<Box>
			<Header />
			<Box className="title" pt="34">
				<Flex
					pt="20px"
					pl={[5, 10, 59.9]}
					direction={{ base: 'column', md: 'row' }}
					mb="5"
				>
					<Text
						color="#303133"
						fontWeight="bold"
						fontSize="3xl"
						pb="15px"
						pl={[0, 0, 5]}
						w={['100%', '100%', '30%']}
					>
						Courses
					</Text>

					<Flex
						w="100%"
						justifyContent={['flex-start', 'flex-start', 'flex-end']}
						alignItems={['flex-start', 'center']}
						pr="55"
					>
						<Hide>
							<AllCoursesSearchBar placeholder="Search for user" />
						</Hide>

						<AddNewCourseDrawer />
					</Flex>
				</Flex>
			</Box>
			<Tabs>
				<TabList ml={[0, 5, 70]}>
					{allModuleCourses.map((course) => (
						<Tab key={course._id}>{course.name}</Tab>
					))}
				</TabList>
				<TabPanels pl={['2', '2', '10']} pr="10" overflow="auto">
					{allModuleCourses.map((course) => (
						<TabPanel key={course._id}>
							<AllCoursesTable
								module={course._id}
								courses={course.courses}
								setAllCourses={setIndividualCourse}
							/>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
		</Box>
	);
	return <div>{!allModuleCourses ? <LoadingSpinner /> : renderTables}</div>;
}
