import {
	Box,
	Flex,
	Text,
	IconButton,
	Stack,
	Collapse,
	Center,
	Icon,
	Link,
	Popover,
	PopoverTrigger,
	useColorModeValue,
	useDisclosure,
	Show,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import headerSelector from '../utils/HeaderSelector';
import { Logout } from '../context/Auth/AuthContext';
import { isLoggedIn } from '../constants/auth';
import '../styles/fontStyle.css';

const NAV_ITEMS: Array<NavItem> = headerSelector();

function DesktopNav() {
	const linkColor = useColorModeValue('white', 'white');
	const linkHoverColor = useColorModeValue('white', 'white');
	const activeColor = useColorModeValue('wheat', 'wheat');

	return (
		<Stack direction="row" spacing={3}>
			<Center>
				{NAV_ITEMS.map((navItem) => (
					<Box key={navItem.label}>
						<Popover trigger="hover" placement="bottom-start">
							<PopoverTrigger>
								<Link
									as={NavLink}
									p={2}
									m={4}
									to={navItem.href ?? '#'}
									fontSize="md"
									fontWeight={700}
									color={linkColor}
									_activeLink={{
										color: activeColor,
									}}
									_hover={{
										textDecoration: 'none',
										color: linkHoverColor,
									}}
								>
									{navItem.label}
								</Link>
							</PopoverTrigger>
						</Popover>
					</Box>
				))}
			</Center>
		</Stack>
	);
}

function MobileNavItem({ label, children, href }: NavItem) {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Stack spacing={3} onClick={children && onToggle}>
			<Flex
				py={2}
				as={Link}
				href={href ?? '#'}
				justify="space-between"
				align="center"
				_hover={{
					textDecoration: 'none',
				}}
			>
				<Text fontSize="md" fontWeight={700} color="white">
					{label}
				</Text>
				{children && (
					<Icon
						as={ChevronDownIcon}
						transition="all .25s ease-in-out"
						transform={isOpen ? 'rotate(180deg)' : ''}
						w={6}
						h={6}
					/>
				)}
			</Flex>

			<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
				<Stack mt={2} pl={4} borderLeft={1} borderStyle="solid" align="start">
					{children &&
						children.map((child) => (
							<Link key={child.label} py={2} href={child.href}>
								{child.label}
							</Link>
						))}
				</Stack>
			</Collapse>
		</Stack>
	);
}

function MobileNav() {
	const linkColor = useColorModeValue('white', 'white');
	const linkHoverColor = useColorModeValue('white', 'white');
	return (
		<Stack bg="#303133" p={4} display={{ md: 'none' }} w="100vw">
			{NAV_ITEMS.map((navItem) => (
				<MobileNavItem
					key={navItem.label}
					label={navItem.label}
					href={navItem.href}
				/>
			))}
			{isLoggedIn && (
				<Box mt="20px" onClick={() => Logout()}>
					<Link
						href="/"
						fontSize="md"
						fontWeight={700}
						color={linkColor}
						_hover={{
							textDecoration: 'none',
							color: linkHoverColor,
						}}
					>
						Logout
					</Link>
				</Box>
			)}
		</Stack>
	);
}
export default function Navigation() {
	const { isOpen, onToggle } = useDisclosure();
	const linkColor = useColorModeValue('white', 'white');
	const linkHoverColor = useColorModeValue('white', 'white');
	return (
		<Box className="header" p={0}>
			<Flex
				w={'100vw'}
				flexDirection="row-reverse"
				bg="#303133"
				data-testid="navbar"
				color={useColorModeValue('white.900', 'white')}
				minH="65px"
				py={{ base: 2 }}
				px={{ base: 4 }}
				margin={0}
				align="center"
			>
				<Flex
					flex={{ base: 1, md: 'auto' }}
					ml={{ base: -2 }}
					display={{ base: 'flex', md: 'none' }}
				>
					<IconButton
						color="whiteAlpha.900"
						onClick={onToggle}
						icon={
							isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
						}
						variant="ghost"
						aria-label="Toggle Navigation"
					/>
				</Flex>
				<Flex justifyContent="space-between" w="full">
					<Logo />
					<Center>
						<Flex display={{ base: 'none', md: 'flex' }}>
							<DesktopNav />
						</Flex>
					</Center>
					{isLoggedIn && (
						<Show above="md">
							<Box mt="20px" onClick={() => Logout()}>
								<Link
									href="/"
									fontSize="md"
									fontWeight={700}
									color={linkColor}
									_hover={{
										textDecoration: 'none',
										color: linkHoverColor,
									}}
								>
									Logout
								</Link>
							</Box>
						</Show>
					)}
				</Flex>
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav />
			</Collapse>
		</Box>
	);
}
interface NavItem {
	label: string;
	subLabel?: string;
	children?: Array<NavItem>;
	href?: string;
}
